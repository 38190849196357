@media (min-width: 1601px) {
  .titleTextSR,.titleTextSR span{
    font-size: 55px !important;
  }}
  .sustainableMetalCSR p {
  padding: 40px 0 0;
}
.sustainableMetalCSR .row {
  padding-bottom: 100px;
}
.sustainableMetalCSR p,
.sustainableMetalCSR h3 {
  width: 75%;
}
@media (min-width: 576px) {
  .keyWrapCSR .eachkey h6 {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 1280px) {
  .sustainableMetalCSR p {
    padding: 30px 0 0;
  }
}
@media (max-width: 450px) {
  .sustainableMetalCSR p {
    padding: 10px 0;
  }
  .sustainableMetalCSR p,
  .sustainableMetalCSR h3 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .sustainableMetalPicSection {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
}
@media (max-width: 992px) {
  .sustainableMetalPicSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  .sustainableMetalCSR .row {
    padding-bottom: 80px;
  }
}
.sustainableMetalPicSection img {
  width: 100%;
  height: auto;
  max-width: 100%;
 
}

.keyWrapCSR .eachkey {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 30px 0px 0px;
  min-height: 150px;
  padding: 13px 18px;
  width: calc(30% - 15px);
}
.keyWrapCSR .eachkey H4 {
  color: #2ba24c;
}
.keyWrapCSR {
  animation: from-right-in 0.8s linear forwards;
  opacity: 0;justify-content: flex-start;
  gap: 20px;
}
@keyframes from-right-in {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 1518px) {
.keyWrapCSR .eachkey{
  margin: 20px 0px 0px;
}.keyWrapCSR{
 gap: 10px;
}.keyWrapCSR .eachkey h4 span {
  font-size: 30px;
 
  line-height: 45px;
  
}.keyWrapCSR .eachkey h6 {
  font-size: 15px;
  line-height: 24px;
}
}
@media (max-width: 1245px) {
  .keyWrapCSR .eachkey {
    width: calc(33% - 15px);
  }
  .keyWrapCSR .eachkey {
    min-height: 170px;
  }
  .keyWrapCSR {
    justify-content: flex-start;
    gap: 20px;
    margin: 20px 0;
  }
  .keyWrapCSR .eachkey {
    margin: 0;
  }
}
@media (max-width: 1550px) {
  .keyWrapCSR .eachkey h6 {
    font-size: 16px;
    line-height: 24px;
  }
}@media (min-width: 1551px){ .keyWrapCSR .eachkey h6 {
  font-size: 20px;
}}
@media (max-width: 992px) {
  .keyWrapCSR .eachkey {
    width: calc(50% - 15px);
  }
  .keyWrapCSR .eachkey {
    border-radius: 15px;

    min-height: 150px;
  }
}@media only screen and (max-width: 768px) {
  .keyWrapCSR {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media (max-width: 575px) {
  .sechead {
      margin-bottom: 20px !important;
    }
  
    .keyWrapCSR .eachkey h6 {
      font-size: 12px;
    }
  
    .keyWrapCSR .eachkey h4,
    .keyWrapCSR .eachkey h4 span {
      font-size: 30px;
    }
  
    .keyWrapCSR .eachkey h4 span {
      font-size: 25px;
    }
  
    .sustainableMetalPicSection {
      gap: 10px;
      padding: 10px;
    }
  .circularFrame {
    padding: 0 10px;
  }
  .keyWrapCSR .eachkey {
    border-radius: 10px;
  }
  .keyWrapCSR .eachkey {
    min-height: 150px;
    padding: 5px;
  }
  .keyWrapCSR {
    gap: 15px;justify-content: flex-start;
  }
  .keyWrapCSR .eachkey {
    min-height: 100px;
    padding: 5px;
  }.keyWrapCSR .eachkey h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
}

