.what-you-will-learn-section {
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

.what-you-will-learn-section::before {
  content: "";
  position: absolute;
  top: -100px;
  right: -100px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: rgba(67, 97, 238, 0.1);
  z-index: 0;
}

.what-you-will-learn-section::after {
  content: "";
  position: absolute;
  bottom: -100px;
  left: -100px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: rgba(67, 97, 238, 0.05);
  z-index: 0;
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a2e;
  margin-bottom: 15px;
}

.section-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1579be;
  margin-top: 0;
}

.learn-content {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}

.learn-image {
  flex: 1;
  max-width: 45%;
}

.learn-image img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease;
}

.learn-image img:hover {
  transform: scale(1.02);
}

.learn-points {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.learn-point {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.point-icon {
  width: 50px;
  height: 50px;
  min-width: 50px;
  background-color: #1579be;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.point-icon i {
  color: white;
  font-size: 20px;
}

.point-content h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
  color: #1a1a2e;
}

.point-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a4a68;
  margin: 0;
}

.learn-points .cta-button {
  margin-top: 15px;
  align-self: flex-start;
}

/* Animation delays */
.section-title.animate {
  transition-delay: 0.1s;
}

.section-subtitle.animate {
  transition-delay: 0.2s;
}

.learn-points .learn-point:nth-child(1) {
  transition-delay: 0.3s;
}

.learn-points .learn-point:nth-child(2) {
  transition-delay: 0.5s;
}

.learn-points .learn-point:nth-child(3) {
  transition-delay: 0.7s;
}

.learn-points .cta-button.animate {
  transition-delay: 0.9s;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
}

/* Responsive styles */
@media (max-width: 992px) {
  .learn-content {
    flex-direction: column;
  }

  .learn-image {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .section-title {
    font-size: 2.2rem;
  }

  .section-subtitle {
    font-size: 1.3rem;
  }
}

@media (max-width: 576px) {
  .what-you-will-learn-section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-subtitle {
    font-size: 1.1rem;
  }

  .learn-point {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .point-icon {
    margin-bottom: 15px;
  }

  .learn-points .cta-button {
    align-self: center;
  }
}
