.disclaimerSectionWrap {
    background-color: #E8F2F8;
}

.disclaimerSectionWrap .npButton {
    margin-top: 60px;
}

.disclaimerWrap {
    display: flex;
    flex-wrap: wrap;
}

/* .disclaimerWrap .founderCont{width: 930px; padding-right: 80px;} */
.disclaimerWrap .founderContBox {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 60px;
}

/* .disclaimerWrap .founderImg{ border-radius: 20px; width: calc(100% - 930px)} */
.disclaimerWrap .founderImg img {
    width: 100%;
    display: block;
}

.disclaimerWrap .founderImg figure {
    margin-bottom: 0;
}@media (max-width: 1200px) {.disclaimerWrap .founderContBox {
    padding: 40px;}
}@media only screen and (max-width: 991px) {.disclaimerWrap .founderContBox {
    margin-bottom: 20px;
    height: auto;}
}@media (max-width: 575px) {.disclaimerWrap .founderContBox {
    padding: 16px;}
}@media (max-width: 575px) {.founderContBox .titleText {
    margin-bottom: 20px;}
}@media (max-width: 1200px){ .disclaimerSectionWrap .npButton {
    margin-top: 30px;}
}@media (max-width: 575px) {.disclaimerSectionWrap .npButton {
    margin-top: 0;}
}.disclaimerWrap .founderImg img {
    width: 100%;
    display: block;
}@media (min-width: 992px) {.col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}}@media (min-width: 992px) {.col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}} .aosAnim.ltr .founderImg{
        opacity: 0;
        animation: from-right-in 0.8s linear forwards;
    }
    @keyframes from-right-in {
    0% {
            opacity: 0;
            transform: translateX(20%);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }