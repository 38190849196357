.privilegeSectionWrap.procureSectionWrap .eachBoxWrap .eachBox {
    width: calc(33.33% - 30px);
}
/* 
         .privilegeSectionWrap{
background-color: #add8e6;
         }  */
.procureSectionWrap {
    padding-bottom: 120px;

}





@media (max-width: 1600px){ .procureSectionWrap {
    padding-bottom: 70px;
}}@media (max-width: 1440px) {
    .procureSectionWrap {
        padding-top: 90px !important;
    }
}@media (max-width: 1280px) {
    .procureSectionWrap {
        padding-top: 80px !important;
        padding-bottom: 60px !important;
    }
}@media (max-width: 575px) {
    .procureSectionWrap {
        padding-bottom: 60px !important;
    }
}

.procureSectionWrap .eachBoxWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}



.procureSectionWrap .eachBoxWrap .eachBox img {
    margin-bottom: 45px;
    display: inline-block;
}

.procureSectionWrap .eachBoxWrap .eachBox h4 {
    margin-bottom: 15px;
}
/*RESPONSIVENESS*/
@media (max-width: 1280px) {.procureSectionWrap .eachBoxWrap .eachBox {
    margin-bottom: 60px;}
}@media only screen and (max-width: 991px) {.privilegeSectionWrap.procureSectionWrap .eachBoxWrap .eachBox {
    width: calc(50% - 30px);}
}@media only screen and (max-width: 768px) {.privilegeSectionWrap.procureSectionWrap .eachBoxWrap .eachBox {
    width: calc(50% - 15px);}
}@media (max-width: 1440px) {.procureSectionWrap .eachBoxWrap .eachBox img {
    margin-bottom: 25px;}
}@media (max-width: 575px) {.procureSectionWrap .eachBoxWrap .eachBox img {
    width: 40px;
    margin-bottom: 20px;}
}@media (max-width: 1440px) {.procureSectionWrap .eachBoxWrap .eachBox h4 {
    font-size: 22px;
    line-height: 32px;}
}@media (max-width: 575px) {.procureSectionWrap .eachBoxWrap .eachBox h4 {
    font-size: 16px;
    line-height: 20px;}
}@media (max-width: 1600px) {.procureSectionWrap .eachBox p {
    font-size: 14px;
    line-height: 24px;}
}@media (max-width: 575px){ .procureSectionWrap .eachBoxWrap .eachBox p {
    font-size: 12px;
    line-height: 23px;
}}