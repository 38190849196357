.scrollSection {
  width: 100%;
  /* height: calc(100vh - 120px); */
  position: relative;
  overflow: hidden;
  transition: 2s;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.investorRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.investorSectionWrap .titleText {
  margin-bottom: 0;
}

.aosAnim.ltr h2 {
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
@keyframes fade-slide-in-ltr {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.section.ltr .investorWrap {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}

@keyframes from-right-in {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
ul.noList {
  margin: 0;
  padding: 0;
  list-style: none;
}
.investorWrap ul li:not(:last-child) {
  margin-bottom: 20px;
}

.investorWrap ul li figure {
  background-color: #fff;
  border-radius: 20px;
  margin: 0;
  width: 475px;
  height: 204px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 60px;
}
@media (max-width: 1600px) {
  .investorWrap ul li figure {
    width: 377px;
    height: 160px;
  }
}
.investorWrap ul li figure img {
  width: 100%;
}
.investorWrap ul li:nth-child(2) {
  margin-left: 280px;
}
.investorWrap li:not(:last-child) {
  margin-right: 0px;
}
.investorWrap ul li:not(:last-child) {
  margin-bottom: 20px;
} /* responsive*/
@media (max-width: 1365px) {
  section {
    padding: 80px 0;
  }
}
@media only screen and (max-width: 1024px) {
  section {
    padding: 80px 0;
  }
}
@media only screen and (max-width: 768px) {
  section {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 640px) {
  section {
    padding: 80px 0;
  }
}
@media (max-width: 1440px) {
  .investorWrap ul li figure {
    width: 320px;
    height: 140px;
  }
}
@media (max-width: 1365px) {
  .investorWrap ul li figure {
    width: 337px;
    height: 126px;
  }
}
@media (max-width: 1200px) {
  .investorWrap ul li figure {
    width: 254px;
    height: 110px;
  }
}
@media only screen and (max-width: 991px) {
  .investorWrap ul li figure {
    padding: 30px;
  }
}
@media only screen and (max-width: 640px) {
  .investorWrap ul li figure {
    display: inline-block;
  }
}
@media only screen and (max-width: 640px) {
  .investorSectionWrap .titleText {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .investorSectionWrap .titleText {
    margin-bottom: 35px;
  }
}
@media (max-width: 1280px) {
  .titleText {
    font-size: 40px;
    line-height: 65px;
  }
}
@media only screen and (max-width: 1023px) {
  .investorWrap ul li:nth-child(2) {
    margin-left: 140px;
  }
}
@media only screen and (max-width: 768px) {
  .investorWrap ul li:nth-child(2) {
    margin-left: 60px;
  }
}
@media only screen and (max-width: 640px) {
  .investorWrap ul li:nth-child(2) {
    text-align: right;
    left: auto;
  }
}
