    .section.ltr .priceSecWrap{
        opacity: 0;
    animation: up 0.8s linear forwards;
    }.priceSec {
        padding: 160px 0 200px;
    }.pricewrap {
        
       
        border-radius: 20px;
        border: 1px solid #aaaaaa;
    }.priceHead {
        padding-bottom: 40px;
        border-bottom: 2px solid #eeeeee;
    }.ic-1 {
        background-color: rgba(150, 54, 225, 0.16);
    }
        .ic-2 {
            background-color: rgba(227, 151, 0, 0.16);
        }.priceIc {
        padding: 12px 20px;
        border-radius: 6px;
    }.priceImg {
        margin-right: 15px;
    }.ic-1 h4 {
        color: #9636e1;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 0;
            font-size: 24px;
            font-weight: 700;
    }
        .ic-2 h4 {font-size: 24px;
            font-weight: 700;
            color: #e39600;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 0;
        }.prBodyWrap {
        padding-top: 40px;
    }.priceBody {
        margin-bottom: 40px;
    }.priceBody h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 20px;
    }@media (max-width: 575px) {.priceBody h4 {
        font-size: 16px;}
    }.priceBody ul {
        padding-left: 0;margin-top: 0;
            margin-bottom: 1rem;
    }.priceBody li {
        display: flex;
        align-items: flex-start;
        list-style: none;
        margin-bottom: 20px;
    }.priceBody li img {
        margin-top: 5px;
        margin-right: 5px;
    }.priceBody li h5 {
        margin-bottom: 0;
        line-height: 28px;
        font-size: 20px;
        font-weight: 400;
    }@media (max-width: 575px){ .priceBody li h5 {
        font-size: 12px;}
    }.prBtnA {
        display: flex;
        justify-content: flex-end;
    }.list-grey {
        color: #aaaaaa;
    }@media(min-width:576px)
{.pricewrap {
    
        padding: 40px;}}
    @media (max-width: 575px) {.prBodyWrap {
        padding-top: 20px;}
    }@media only screen and (max-width: 768px) {.sechead {
        margin-bottom: 16px;
    }}
    
    @media (max-width: 1366px) and (max-height: 768px){ .sechead {
        margin-bottom: 40px;}
    }@media (max-width: 400px) {.priceHead {
        padding-bottom: 10px;}
    }@media only screen and (max-width: 768px) {.priceIc {
        padding: 6px;
    }}
    
    @media only screen and (max-width: 1024px) {.priceIc {
        height: 30px;}
    }@media only screen and (max-width: 768px) {.priceImg {
        margin-right: 5px;
    }}
    
    @media only screen and (max-width: 768px) {.priceImg {
        width: 12px;}
    }@media only screen and (max-width: 768px) {.priceImg img {
        width: 100%;}
    }@media only screen and (max-width: 768px){ .priceIc h4 {
        font-size: 12px;}
    }@media (max-width: 575px){ .prBodyWrap {
        padding-top: 20px;}
    }@media (max-width: 400px) {.priceBody {
        margin-bottom: 20px;}
    }@media (max-width: 400px){ .priceBody h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 10px;}
    }@media (max-width: 575px) {.priceBody li {
        margin-bottom: 9px;}
    }@media (max-width: 400px) {.priceBody li img {
        width:8px;}}@media (max-width: 400px) {.priceBody li h5 {
            font-size: 12px;
            line-height: 18px;}
        }@media (max-width: 575px) {.prBtnA .npButton {
            padding: 12px 16px;
        }
        }
        @media only screen and (max-width: 768px) {.npButton {
            padding: 8px 16px;
            font-size: 12px;
            line-height: 16px;
        }}