.bannerPolyCSR a {
  padding: 0;
  background: none;
  height: 235px;
  width: 244px;
}

.bannerLeft span {
  font-size: 64px !important;
  line-height: 74px;
}

.marketbnrCSR {
  padding-top: 120px !important;
}

.triangleTopLeftBgCSR p {
  width: 75%;
  margin-top: 10px;
}
.triangleTopLeftBgCSR h3 span {
  background: -webkit-linear-gradient(to right, #1579be, #5e93b8);
  background: linear-gradient(to right, #1579be, #5e93b8);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}
.circularFrameMobile img {
  display: none;
}

.circularFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  position: relative;
}

.circularFramePics {
  position: absolute;
}
.circularFramePic {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  padding: 5px;
  background-color: #f0f5f7;
  width: 120px;
  height: 75px;
}
.circularFramePic1 {
  left: -543px;
  top: -183px;
}
.circularFramePic2 {
  left: -343px;
  top: -281px;
}
.circularFramePic3 {
  left: 243px;
  top: -281px;
}
.circularFramePic4 {
  left: 443px;
  top: -183px;
}
.circularFramePic5 {
  left: -485px;
  top: 176px;
}

.circularFramePic6 {
  left: 360px;
  top: 183px;
}
.circularFramePic8 {
  left: 40px;
  top: 183px;
}
.circularFramePic7 {
  left: -160px;
  top: 183px;
}
@media (max-width: 1170px) {
  .circularFramePic5 {
    left: -480px;
  }
}

@media (max-width: 1150px) {
  .circularFramePic4 {
    left: 394px;
  }
  .circularFramePic6 {
    left: 333px;
  }
  .circularFramePic5 {
    left: -449px;
  }
  .circularFramePic1 {
    left: -492px;
  }
}
@media (max-width: 1060px) {
  .circularFramePic6 {
    left: 315px;
  }
  .circularFramePic3 {
    left: 210px;
    top: -258px;
  }
  .circularFramePic2 {
    left: -301px;
    top: -258px;
  }
  .circularFramePic5 {
    left: -428px;
  }
}

@media (max-width: 910px) {
  .circularFramePic4 {
    left: 335px;
  }
  .circularFramePic6 {
    left: 285px;
  }
  .circularFramePic3 {
    left: 163px;
  }
  .circularFramePic5 {
    left: -404px;
  }
  .circularFramePic1 {
    left: -430px;
  }
  .circularFramePic8,
  .circularFramePic7 {
    top: 148px;
  }
}
@media (max-width: 830px) {
  .circularFramePic4 {
    left: 304px;
    top: -149px;
  }
  .circularFramePic1 {
    top: -149px;
  }
  .circularFramePic2,
  .circularFramePic3 {
    top: -220px;
  }
  .circularFramePic6 {
    left: 266px;
    top: 141px;
  }
  .circularFramePic5 {
    left: -384px;
    top: 141px;
  }
}
@media (max-width: 768px) {
  .circularFramePic6 {
    left: 247px;
    top: 134px;
  }
  .circularFramePic8,
  .circularFramePic7 {
    top: 129px;
  }
  .circularFramePic5 {
    left: -367px;
  }
  .circularFramePic2 {
    left: -261px;
  }
}
@media (max-width: 700px) {
  .circularFramePic8 {
    left: 6px;
    top: 122px;
  }
  .circularFramePic7 {
    left: -135px;
    top: 122px;
  }
  .circularFramePic3 {
    left: 121px;
    top: -202px;
  }
  .circularFramePic2 {
    left: -249px;
    top: -202px;
  }
  .circularFramePic {
    width: 100px;
    height: 60px;
  }
  .circularFramePic1 {
    left: -339px;
    top: -125px;
  }
  .circularFramePic4 {
    left: 240px;
    top: -125px;
  }
  .circularFramePic5 {
    left: -325px;
    top: 115px;
  }
  .circularFramePic6 {
    left: 230px;
    top: 115px;
  }
}

.circularFramePic img {
  width: 40%;
}
.circularFramePic p {
  white-space: nowrap;
  margin: 0px !important;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.circularFrame.aosAnim1 svg {
  max-width: 100%;
  max-height: 100%;
  padding: 30px;
  opacity: 0;
  animation: slideUpFrame 800ms linear forwards;
}
.circularFrame svg img {
  background-color: #fff;
  padding: 30px;
  margin: 40px;
}

.circularFrame.aosAnim1 .line,
.circularFrame.aosAnim1 .circularFramePic,
.circularFrame.aosAnim1 .curveLine {
  animation: lineOp 1s linear forwards;
  animation-delay: 2.5s;
  opacity: 0;
}
.circularFrame.aosAnim1 .circle1 {
  animation: circle1 0.5s linear forwards;
  animation-delay: 1s;
}
.circularFrame.aosAnim1 .circle2 {
  animation: circle2 0.5s linear forwards;
  animation-delay: 1s;
}
.circularFrame.aosAnim1 .circle3 {
  position: relative;
  animation: circle3 0.5s linear forwards;
  animation-delay: 1s;
}
.circularFrame.aosAnim1 .circle4 {
  animation: circle4 0.5s linear forwards;
  animation-delay: 1s;
}
.circularFrame.aosAnim1 .circle5 {
  animation: circle5 0.5s linear forwards;
  animation-delay: 1s;
}

.circularFrame.aosAnim1 .circle6 {
  animation: circle6 0.5s linear forwards;
  animation-delay: 1s;
}

.circularFrame.aosAnim1 .circle7 {
  animation: circle7 0.5s linear forwards;
  animation-delay: 1s;
}

.circularFrame.aosAnim1 .circle8 {
  animation: circle8 0.5s linear forwards;
  animation-delay: 1s;
}

@keyframes slideUpFrame {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }

  50% {
    opacity: 0.5;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes lineOp {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes circle1 {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  50% {
    opacity: 1;
    transform: translate(-20%, -15%);
  }

  100% {
    opacity: 1;
    transform: translate(-35%, -30%);
  }
}
@keyframes circle2 {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  50% {
    opacity: 1;
    transform: translate(-20%, 15%);
  }

  100% {
    opacity: 1;
    transform: translate(-35%, 30%);
  }
}
@keyframes circle6 {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  50% {
    opacity: 1;
    transform: translate(20%, -15%);
  }

  100% {
    opacity: 1;
    transform: translate(35%, -30%);
  }
}
@keyframes circle7 {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  50% {
    opacity: 1;
    transform: translate(20%, 15%);
  }

  100% {
    opacity: 1;
    transform: translate(35%, 30%);
  }
}
@keyframes circle3 {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  50% {
    opacity: 1;
    transform: translate(-5%, -10%);
  }

  100% {
    opacity: 1;
    transform: translate(-10%, -20%);
  }
}

@keyframes circle4 {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  50% {
    opacity: 1;
    transform: translate(-5%, 10%);
  }

  100% {
    opacity: 1;
    transform: translate(-10%, 20%);
  }
}

@keyframes circle5 {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  50% {
    opacity: 1;
    transform: translate(5%, -10%);
  }

  100% {
    opacity: 1;
    transform: translate(10%, -20%);
  }
}

@keyframes circle8 {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  50% {
    opacity: 1;
    transform: translate(5%, 10%);
  }

  100% {
    opacity: 1;
    transform: translate(10%, 20%);
  }
}
.logoSection {
  background-color: #f0f5f7;
  border-radius: 15px;
  padding: 10px 30px;
  width: 175px;
}
@media (max-width: 1600px) {
  .bannerLeft h2,
  .bannerLeft span {
    font-size: 50px !important;
    line-height: 74px;
  }
}

@media (min-width: 1280px) {
  .bannerPolyCSR a {
    height: 235px;
    width: 244px;
  }
}

@media only screen and (max-width: 991px) {
  .bannerLeft h2,
  .bannerLeft span {
    font-size: 36px !important;
  }

  .marketbnrCSR {
    padding-top: 80px !important;
  }
}

@media (min-width: 990px) {
  .bannerPolyCSR a {
    height: 135px;
    width: 144px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .marketbnrCSR .col-md-7 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .marketbnrCSR .col-md-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .bannerPolyCSR a {
    height: 135px;
    width: 144px;
  }
}
@media (max-width: 665px) {
  .circularFrame {
    display: none;
  }

  .circularFrameMobile img {
    display: block;
    width: 100%;
  }

  .circularFrame img {
    display: none;
  }

  .circularFrameMobile {
    width: 100%;
    padding: 20px;
  }
}
@media (max-width: 575px) {
  .bannerPolyCSR a {
    height: 135px;
    width: 144px;
  }
}
