.why-it-matters-section {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
}

.why-it-matters-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(#e8ecff 1px, transparent 1px);
  background-size: 20px 20px;
  opacity: 0.5;
  z-index: 0;
}

.why-it-matters-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
  color: #1a1a2e;
  position: relative;
}

.section-title::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background-color: #1579be;
  border-radius: 2px;
}

.cost-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 50px;
}

.cost-item {
  background-color: #f8f9ff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cost-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.cost-icon {
  width: 60px;
  height: 60px;
  background-color: #1579be;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cost-icon i {
  color: white;
  font-size: 24px;
}

.cost-content h3 {
  font-size: 20px;
  line-height: 1.25;
  font-weight: 600;
  margin-bottom: 15px;
  color: #1a1a2e;
}

.annual-loss {
  background-color: #1a1a2e;
  border-radius: 12px;
  padding: 40px;
  text-align: center;
  margin-top: 40px;
}

.annual-loss .break-line-tag {
  display: none;
}

.annual-loss h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  margin-bottom: 25px;
}

.annual-loss .highlight {
  font-size: 1.8rem;
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

.annual-loss .cta-button {
  text-transform: uppercase;
  background-color: #ff6b6b;
  padding: 10px 20px;
  margin-top: 10px;
}

.annual-loss .cta-button:hover {
  background-color: #ff5252;
}

/* Animation delays */
.cost-items .cost-item:nth-child(1) {
  transition-delay: 0.1s;
}

.cost-items .cost-item:nth-child(2) {
  transition-delay: 0.3s;
}

.cost-items .cost-item:nth-child(3) {
  transition-delay: 0.5s;
}

.annual-loss.animate {
  transition-delay: 0.7s;
}

/* Responsive styles */
@media (max-width: 768px) {
  .annual-loss .break-line-tag {
    display: block;
  }
  .section-title {
    font-size: 2rem;
  }

  .cost-items {
    grid-template-columns: 1fr;
  }

  .annual-loss h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .why-it-matters-section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .cost-item {
    padding: 20px;
  }

  .annual-loss {
    padding: 30px 20px;
  }

  .annual-loss h3 {
    font-size: 1.3rem;
  }
}
