.hero-section {
  padding: 80px 0;
  overflow: hidden;
  position: relative;
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero-section-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}

.hero-title {
  font-size: 50px;
  line-height: 1.2;
  font-weight: 700;
  color: #1a1a2e;
}

.title-description {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
}

.hero-title .highlight {
  font-size: 44px;
  font-weight: 700;
  color: #1579be;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.hero-subtitle {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #4361ee;
}

.hero-description {
  font-size: 20px;
  margin-bottom: 20px;
  color: #4a4a68;
  font-weight: 400;
}

.hero-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
}

.hero-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.5s ease;
}

.hero-image:hover {
  transform: translateY(-10px);
}

/* Animation delays */
.hero-title.animate {
  transition-delay: 0.1s;
}

.hero-subtitle.animate {
  transition-delay: 0.3s;
}

.hero-description.animate {
  transition-delay: 0.5s;
}

/* Responsive styles */
@media (max-width: 992px) {
  .hero-container {
    flex-direction: column;
    text-align: center;
  }

  .hero-section-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .hero-image-container {
    justify-content: center;
  }

  .hero-title {
    font-size: 2.8rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .hero-section {
    padding: 60px 0;
  }

  .hero-title {
    font-size: 2.2rem;
  }

  .hero-subtitle {
    font-size: 1.3rem;
  }

  .hero-description {
    font-size: 1rem;
  }
}
