.keyWrapEnv .eachkey {
  width: calc(33% - 40px);

  border-radius: 15px;
  padding: 15px;
  min-height: 150px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.keyWrap1{
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
}
.iEnv{
    width: 68px;
  }.iEnv img{
    width: 100%;
  }
.pEnv,
.pEnv span {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
@media (max-width: 1285px) {
  .keyWrapEnv .eachkey {
    width: calc(33% - 30px);
  }
}
@media (max-width: 1130px) {
  .keyWrapEnv .eachkey {
    width: calc(33% - 10px);
  }
  .pEnv span {
    line-height: 30px;
  }
}
@media (max-width: 1060px) {
  .keyNumber .eachkey {
    padding: 20px;
  }
  .pEnv,
  .pEnv span {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
}@media (max-width: 928px)
{
  .iEnv {
      width: 48px;
    }.pEnv,
    .pEnv span {
      font-size: 16px;
    
      line-height: 20px;
    }
}
@media only screen and (max-width: 768px) {
  .keyWrapEnv .eachkey {
    min-height: 180px;
  }
  .keyWrapEnv .eachkey {
    padding: 10px;
  }
  
 .keyWrap1 {
  
   gap: 20px;
 }
}
@media (max-width: 575px) {
  .keyWrap1 {
      display: flex;
      flex-direction: column;
      gap: 15px;
}.keyWrapEnv .eachkey {
  width:100%;
}.keyWrapEnv .eachkey {
  min-height: 150px;
}
}