.treeplantation h2,
.treeplantation h2 span {
  font-size: 50px !important;
}
@media (max-width: 1285px) {
  .treeplantation h2,
  .treeplantation h2 span {
    font-size: 40px !important;
  }
}
@media (max-width: 575px) {
  .treeplantation h2 {
    font-size: 28px !important;
    line-height: 40px !important;
  }
}
.treePlantationImgSection img {
  width: 100%;
}
.keyWrapTP {
  width: calc(50% - 20px);
}
.treePlantationImgSection {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
@media (max-width: 1285px) {
  .treePlantationImgSection {
    gap: 30px;
  }

  .keyWrapTP {
    width: calc(50% - 15px);
  }
}
@media (max-width: 991px) {
  .treePlantationImgSection {
    gap: 20px;
  }
  .keyWrapTP {
    width: calc(50% - 10px);
  }
}
@media (max-width: 575px) {
  .keyWrapTP {
    width: 100%;
  }
  .treePlantationImgSection {
    gap: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .treeplantation h2,
  .treeplantation h2 span {
    font-size: 28px !important;
    line-height: 40px;
  }
}
