.eachMediaBoxAcc1 {
  width: calc(33% - 40px);
  border: 1px solid #aaaaaa;
  border-radius: 20px;
}
.mediaRow1 .sechead h3 {
  font-size: 40px !important;
}
.eachMediaBoxAcc1 figure {
  width: 100%;
  height: 250px; /* Set a fixed height for uniformity */
  overflow: hidden; /* Crop the image if it's taller than 250px */
}
.eachMediaBoxAcc1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mediaRow1 p {
  padding-bottom: 25px;
}
.mediaTextAcc1 {
  padding: 0 20px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}
.eachMediaBoxAcc1 {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}
@media (max-width: 1440px) {
  .mediaRow1 .sechead h3 {
    font-size: 30px !important;
  }
}
@media (max-width: 1024px) {
  .mediaRow1 .sechead h3 {
    font-size: 26px !important;
  }
}
@media (max-width: 575px) {
  .mediaRow1 .sechead h3 {
    font-size: 20px !important;
  }
}
@media (max-width: 1440px) {
  .eachMediaBoxAcc1 {
    width: calc(33.33% - 30px);
  }
}

@media (max-width: 1200px) {
  .eachMediaBoxAcc1 {
    width: calc(33.33% - 20px);
    padding-bottom: 15px;
  }
  .mediaTextAcc1 {
    min-height: 190px;
    padding: 0 15px;
  }
}

@media (max-width: 900px) {
  .eachMediaBoxAcc1 {
    width: calc(33.33% - 10px);
    padding-bottom: 15px;
  }
}
@media (max-width: 786px) {
  .eachMediaBoxAcc1 figure {
    width: 100%;
    height: 450px; /* Set a fixed height for uniformity */
    overflow: hidden; /* Crop the image if it's taller than 250px */
  }
  .mediaRow1 p {
    font-size: 16px;
  }
  .eachMediaBoxAcc1 {
    width: 100%;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .mediaRow1 .sechead {
    margin-bottom: 20px;
  }
}
@media (max-width: 900px) {
  .mediaTextAcc1 {
    padding: 0 10px;
  }
  .mediaRow1 p {
    padding-bottom: 15px;
  }
}
@media (max-width: 560px) {
  .eachMediaBoxAcc1 {
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .mediaTextAcc1 {
    padding: 0 15px;
  }
  .mediaTextAcc1 {
    min-height: 100px;
  }
}
.section.ltr .eachMediaBoxAcc1:nth-child(1) {
  opacity: 0;
  animation: up 0.8s linear forwards;
}

.section.ltr .eachMediaBoxAcc1:nth-child(2),
.trWrap.ltr .trBox:nth-child(2) {
  opacity: 0;
  animation: up 1.1s linear forwards;
}

.section.ltr .eachMediaBoxAcc1:nth-child(3),
.trWrap.ltr .trBox:nth-child(3) {
  opacity: 0;
  animation: up 1.3s linear forwards;
}

.rs-picker-default
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-indicator
  .rs-picker-caret-icon,
.rs-picker-default
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-indicator
  .rs-picker-clean {
  top: 14px;
}
