.signup-form-section {
  padding: 80px 0;
  background-color: #ffffff;
  position: relative;
}

.form-header {
  text-align: center;
  margin-bottom: 50px;
}

.form-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a2e;
  margin-bottom: 15px;
}

.form-subtitle {
  font-size: 1.2rem;
  color: #4a4a68;
  max-width: 700px;
  margin: 0 auto;
}

.form-content {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.form-wrapper {
  flex: 1;
  border-radius: 12px;
  padding: 40px;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #1a1a2e;
}

.form-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #e1e5f2;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.15);
}

.submit-button {
  width: 100%;
  background-color: #4361ee;
  color: white;
  font-weight: 600;
  padding: 14px 28px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #3a56d4;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(67, 97, 238, 0.15);
}

.privacy-note {
  text-align: center;
  font-size: 0.9rem;
  color: #4a4a68;
  margin-top: 20px;
  font-weight: 600;
}

.success-message {
  text-align: center;
  padding: 30px 20px;
}

.success-icon {
  width: 70px;
  height: 70px;
  background-color: #4bb543;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin: 0 auto 20px;
}

.success-message h3 {
  font-size: 1.8rem;
  color: #1a1a2e;
  margin-bottom: 15px;
}

.success-message p {
  font-size: 1.1rem;
  color: #4a4a68;
}

.form-benefits {
  flex: 1;
  padding: 30px;
}

.form-benefits h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1a1a2e;
  margin-bottom: 30px;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefits-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.benefit-icon {
  font-size: 1.5rem;
  margin-right: 15px;
  min-width: 30px;
}

.benefit-text {
  font-size: 1.1rem;
  color: #4a4a68;
  line-height: 1.5;
}

.benefit-text strong {
  color: #1a1a2e;
  display: block;
  margin-bottom: 5px;
}

/* Animation delays */
.form-header.animate {
  transition-delay: 0.1s;
}

.form-wrapper.animate {
  transition-delay: 0.3s;
}

.form-benefits.animate {
  transition-delay: 0.5s;
}

/* Responsive styles */
@media (max-width: 992px) {
  .form-content {
    flex-direction: column;
  }

  .form-benefits {
    order: -1;
    margin-bottom: 40px;
  }

  .form-title {
    font-size: 2.2rem;
  }
}

@media (max-width: 576px) {
  .signup-form-section {
    padding: 60px 0;
  }

  .form-wrapper {
    padding: 25px;
  }

  .form-title {
    font-size: 1.8rem;
  }

  .form-subtitle {
    font-size: 1rem;
  }

  .form-benefits h3 {
    font-size: 1.5rem;
  }

  .benefit-text {
    font-size: 1rem;
  }
}

/* Date Picker Styles */
.date-picker-container {
  position: relative;
  width: 100%;
}

.date-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.date-input {
  cursor: pointer;
  background-color: #fff;
  padding-right: 40px !important;
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #e1e5f2;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.date-input:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.15);
}

.calendar-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #4361ee;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 24px;
  height: 24px;
  transition: color 0.2s ease;
}

.calendar-icon svg {
  width: 100%;
  height: 100%;
}

.calendar-icon:hover {
  color: #3a56d4;
}

/* Custom styles for react-datepicker */
.custom-datepicker-wrapper {
  width: 100%;
}

.date-picker-popper {
  z-index: 1000 !important;
}

.react-datepicker {
  font-family: inherit !important;
  border-radius: 8px !important;
  border: 1px solid #e1e5f2 !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.react-datepicker__header {
  background-color: #f8f9fa !important;
  border-bottom: 1px solid #e1e5f2 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding-top: 10px !important;
}

.react-datepicker__current-month {
  font-weight: 600 !important;
  color: #1a1a2e !important;
}

.react-datepicker__day-name {
  color: #4a4a68 !important;
  font-weight: 500 !important;
}

.react-datepicker__day {
  border-radius: 4px !important;
  transition: background-color 0.2s ease, color 0.2s ease !important;
}

.react-datepicker__day:hover {
  background-color: #f0f2ff !important;
}

.react-datepicker__day--selected {
  background-color: #4361ee !important;
  color: white !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: rgba(67, 97, 238, 0.2) !important;
  color: #4361ee !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #e1e5f2 !important;
}

.react-datepicker__time-container .react-datepicker__time {
  border-radius: 0 0 8px 0 !important;
}

.react-datepicker__time-container .react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker__time-list-item {
  transition: background-color 0.2s ease, color 0.2s ease !important;
}

.react-datepicker__time-list-item:hover {
  background-color: #f0f2ff !important;
}

.react-datepicker__time-list-item--selected {
  background-color: #4361ee !important;
}

.react-datepicker__close-icon {
  right: 10px !important;
}

.react-datepicker__close-icon::after {
  background-color: #4361ee !important;
}

.react-datepicker__navigation {
  top: 12px !important;
}

.react-datepicker__navigation--previous {
  left: 12px !important;
}

.react-datepicker__navigation--next {
  right: 12px !important;
}

.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 110px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #4361ee !important;
  color: white !important;
}

/* Mobile responsiveness for date picker */
@media (max-width: 576px) {
  .react-datepicker-popper {
    width: 90% !important;
    max-width: 320px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    position: fixed !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1100 !important;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }

  .react-datepicker {
    width: 100% !important;
  }

  .react-datepicker__time-container {
    width: 100% !important;
    border-left: none !important;
    border-top: 1px solid #e1e5f2 !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100% !important;
    max-height: 150px !important;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px !important;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 10px !important;
  }

  /* Add a backdrop for mobile */
  .react-datepicker-popper::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
}

/* Loading Spinner */
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.npButton.loading {
  opacity: 0.8;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Remove rsuite styles that are no longer needed */
/* ... existing code ... */

/* Mobile DatePicker Styles */
.mobile-datepicker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.2s ease-in-out;
}

.mobile-datepicker-container {
  background-color: white;
  border-radius: 12px;
  width: 90%;
  max-width: 320px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.3s ease-out;
}

.mobile-datepicker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e1e5f2;
}

.mobile-datepicker-header h3 {
  margin: 0;
  font-size: 18px;
  color: #1a1a2e;
}

.mobile-datepicker-close {
  background: none;
  border: none;
  font-size: 24px;
  color: #4a4a68;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.mobile-datepicker-close:hover {
  background-color: #f0f2ff;
}

.mobile-calendar {
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
}

.mobile-datepicker-footer {
  padding: 15px;
  border-top: 1px solid #e1e5f2;
  display: flex;
  justify-content: flex-end;
}

.mobile-datepicker-apply {
  background-color: #4361ee;
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.mobile-datepicker-apply:hover {
  background-color: #3a56d4;
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Custom styles for react-datepicker */
/* ... existing code ... */
