.fundingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 80px 0;
}
.fundingHeader {
  text-align: left;
  font-size: 48px;
  line-height: 1.25;
  font-weight: 700;
  text-align: center;
}

.iconContainer {
  display: flex;
  gap: 24px;
}

.iconContainer > .info {
  opacity: 0.7;
  font-size: 16px;
}

.description {
  font-size: 16px;
  font-weight: 500;
}

.leftBorderIndicator {
  border-left: 4px solid #1579be;
  padding-left: 16px;
}

.leftBorderIndicator > .italicsText {
  opacity: 0.7;
}

.description > strong {
  line-height: normal;
}

.description > b {
  font-size: inherit;
}

.description > .italicsText {
  margin-top: 12px;
}

.description a {
  font-size: inherit;
  line-height: normal;
  font-weight: 700;
  color: #1579be;
}

.fundingContainer .fundingImg {
  box-shadow: 0 4px 8px 0 rgba(21, 121, 190, 0.2),
    /* Pinch of #1579be in the first shadow */ 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 24px;
  max-height: 400px;
  width: fit-content;
  border-radius: 16px;
}

.italicsText {
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}

@media (max-width: 1268px) {
  .fundingHeader {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .fundingHeader {
    font-size: 32px;
  }
  .description {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }
  .italicsText {
    line-height: 30px;
  }
  .fundingContainer .fundingImg {
    width: -webkit-fill-available;
  }
}

@media (max-width: 768px) {
  .fundingContainer {
    margin: 40px 0;
  }
  .description {
    font-size: 14px;
    line-height: normal;
    line-height: 24px;
  }
  .italicsText {
    line-height: 24px;
  }
  .fundingHeader {
    font-size: 24px;
  }
  .fundingHeader > br {
    display: none;
  }
}
