.teamCulture .scrollSection {
  /* height: 150vh; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 2s;
}

.gallery-container {
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-rows: 1fr 1fr;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  max-height: 400px;
}
.child-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  max-height: inherit;
}
.image-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.more-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  cursor: pointer;
}

.more-overlay .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 8px;
}

.overlay > span {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
