.getInTouchForm.visibleBlack {
    opacity: 1;
    transition: 1.5s;
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.6);
}.getInTouchForm {
   
    height: 100vh;
    z-index: 9999;
    top: 0;
   
    width: 100%;
    position: fixed;
   
}

.getInTouchFormWrap {
    visibility: visible;
        height: auto;
         
            left: 50%;
            width: 100%;
            padding: 40px;
            max-width: 853px;
            position: relative;
            background-color: #fff;
            transform: translate(-50%, -50%) scaleY(1);
            border-radius: 17px;
            transition: 2s;
}.getInTouchForm.openForm .getInTouchFormWrap {
    top: 50%;
}.getInTouchFormWrap h3 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    /* text-align: center; */
    margin-bottom: 40px;
    color: #000;
}.formBox .inputBox:not(:last-child) {
    margin-bottom: 20px;
}.formBox label {
    /* font-family: Oxanium; */
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
    margin-bottom: 10px;
    display: block;
    border-radius: 4px;
}.formBox label span {
    color: #e43e2b;
}.formBox .inputBox input[type="text"],
.formBox .inputBox input[type="tel"]{
        width: 100%;
        height: 50px;
        padding: 17px 16px;
        display: block;
        font-size: 18px;
        color: #aaaaaa;
        line-height: 30px;
        border-radius: 4px;
        outline: none !important;
        border: 1px solid #aaaaaa;
    }.formBox .inputBox.btnBox {
        text-align: center;
    }
    
    .formBtns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }.submitMsg.showMsg1 {
        top: 50%;
        /* opacity: 1; */
    }
    
    .submitMsg {
        opacity: 0;
        
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%) scaleY(0);
        position: absolute;
        max-width: 853px;
        border-radius: 17px;
        background-color: #fff;
        padding: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 2s;
        /* z-index: 1; */
    }.submitMsg .submitIc {
        margin-bottom: 20px;
    }.submitMsg h4 {
        color: #2ba24c;
        text-align: center;
        font-size: 32px;
        /* font-family: Oxanium; */
        font-weight: 700;
        line-height: 150%;
    }.submitMsg p {
        color: #909090;
        text-align: center;
        font-size: 20px;
        /* font-family: Oxanium; */
        font-weight: 500;
        line-height: 150%;
    }p:last-child {
        margin-bottom: 0 !important;
    }.formBox .inputBox select {
        height: 50px;
       
        appearance: none;
        font-weight: 300;
        font-size: 18px;
        border: 1px solid #aaaaaa;
        display: block;
        /* padding: 15px; */
        width: 100%;
        padding-left: 15px;
    }.formBox .inputBox.btnBox {
        text-align: center;
    }
    
    .formBtns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }.formBox .inputBox textarea {
        resize: none;
        height: 240px;
        padding: 10px;
        line-height: 1.8;
    }.canecelBtn {
        display: flex;
        align-items: center;
        padding: 11px 20px;
        background-color: #fff;
        border: 1px solid #1579be;
        margin-right: 16px;
        border-radius: 4px;
        cursor: pointer;
    }.canecelBtn img {
        margin-right: 11px;
    }.canecelBtn h5 {
        color: #3877b9;
        margin-bottom: 0;
    }.submitEnq {
        cursor: pointer;
    }.formBox .inputBox button[type="submit"] {
        color: #fff;
        border: 0;
        outline: none;
        /* background-color: transparent; */
        justify-content: center;
        display: flex;
        align-items: center;
        border: 0;
        font-size: 18px;
        box-shadow: none;
        color: #ffffff;
        font-weight: 700;
        line-height: 24px;
        border-radius: 4px;
        padding: 11px 20px;
        letter-spacing: 0.08rem;
        text-transform: capitalize;
        background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
    }.submitEnq img {
        margin-right: 12px;
    }.submitMsg.showMsg1 {
        top: 50%;
        /* opacity: 1; */
    }.submitMsg {
        opacity: 0;
       
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%) scaleY(0);
        position: absolute;
        max-width: 853px;
        border-radius: 17px;
        background-color: #fff;
        padding: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 2s;
        /* z-index: 1; */
    }.submitMsg .submitIc {
        margin-bottom: 20px;
    }.submitMsg h4 {
        color: #2ba24c;
        text-align: center;
        font-size: 32px;
        /* font-family: Oxanium; */
        font-weight: 700;
        line-height: 150%;
    }.submitMsg p {
        color: #909090;
        text-align: center;
        font-size: 20px;
        /* font-family: Oxanium; */
        font-weight: 500;
        line-height: 150%;
    }
    
    p:last-child {
        margin-bottom: 0 !important;
    }