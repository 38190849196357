.mrai-certificate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 80px;
  height: 400px;
}
.sechead {
  position: relative;
  /* transform: translateX(-100%); */
}
