@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@200;300;400;500;600;700;800&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 38px;
  font-weight: 400;
  font-family: "Oxanium", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  /* width: 100%; */
  padding-right: 15px;
  padding-left: 15px;
}
.titleText .skyText {
  font-weight: 700;
  color: #2078bd;
}

.fadedBackground {
  background: linear-gradient(
    180deg,
    rgba(208, 228, 242, 0) 30.13%,
    rgba(208, 228, 242, 0.79) 47.36%,
    #d0e4f2 85.21%,
    rgba(161, 201, 229, 0) 100%
  );
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

a:-webkit-any-link {
  cursor: pointer;
}
.megaMenuNew {
  background-clip: inherit;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform-origin: center top 0;
  transform-origin: center top 0;
  padding-top: 30px;
  transition: 0.6s;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  width: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  background-color: transparent;
}

a {
  font-size: 20px;
  transition: 0.6s;
  color: #303030;
  text-decoration: none !important;
}
.titleText {
  font-size: 50px;
  margin: 0 0 35px;
  color: #303030;
}
.section {
  padding: 60px 0;
}
@media (max-width: 1365px) {
  .section {
    padding: 40px 0 !important;
  }
}
@media only screen and (max-width: 640px) {
  .section {
    padding: 40px 0 !important;
  }
}
p {
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  color: #606060;
}
@media (max-width: 1600px) {
  .titleText .skyText {
    font-size: 50px;
  }
}
@media (max-width: 1440px) {
  .titleText,
  .titleText .skyText {
    font-size: 40px;
    line-height: 1.5;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .titleText,
  .titleText .skyText,
  .bannerLeft h2 {
    line-height: 1.2;
  }
}

@media (max-width: 1600px) {
  .titleText {
    font-size: 50px;
    margin: 0 0 35px;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .titleText {
    line-height: 1.2;
  }
}
@media (max-width: 1440px) {
  .titleText,
  .titleText .skyText {
    font-size: 40px;
    line-height: 1.5;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .titleText,
  .titleText .skyText,
  .bannerLeft h2 {
    line-height: 1.2;
  }
}
