.bonus-offer-section {
  padding: 80px 0;
  background-color: #f5f7ff;
  position: relative;
}

.bonus-offer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.bonus-content {
  display: flex;
  align-items: center;
  gap: 50px;
}

.bonus-text {
  flex: 1;
}

.bonus-title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #1a1a2e;
  margin-bottom: 20px;
}

.bonus-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #4a4a68;
  margin-bottom: 30px;
}

.bonus-image {
  flex: 1;
  max-width: 45%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bonus-image img {
  width: auto;
  max-height: 320px;
  transition: transform 0.5s ease;
}

.bonus-image img:hover {
  transform: scale(1.02);
}

/* Popup styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-container {
  background-color: white;
  border-radius: 12px;
  padding: 40px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.4s ease;
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.8rem;
  color: #4a4a68;
  cursor: pointer;
  transition: color 0.3s ease;
}

.popup-close:hover {
  color: #1a1a2e;
}

.popup-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1a1a2e;
  margin-bottom: 10px;
  text-align: center;
}

.popup-subtitle {
  font-size: 1rem;
  color: #4a4a68;
  margin-bottom: 30px;
  text-align: center;
}

.popup-form-group {
  margin-bottom: 20px;
}

.popup-form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #1a1a2e;
}

.popup-form-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #e1e5f2;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.popup-form-group input:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.15);
}

.popup-submit {
  width: 100%;
  background-color: #4361ee;
  color: white;
  font-weight: 600;
  padding: 14px 28px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.popup-submit:hover {
  background-color: #3a56d4;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(67, 97, 238, 0.15);
}

.popup-success {
  text-align: center;
  padding: 20px 0;
}

.popup-success .success-icon {
  width: 70px;
  height: 70px;
  background-color: #4bb543;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin: 0 auto 20px;
}

.popup-success h3 {
  font-size: 1.8rem;
  color: #1a1a2e;
  margin-bottom: 15px;
}

.popup-success p {
  font-size: 1.1rem;
  color: #4a4a68;
}

/* Loader styles */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Disabled button styles */
.npButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Responsive styles */
@media (max-width: 992px) {
  .bonus-content {
    flex-direction: column;
  }

  .bonus-image {
    max-width: 100%;
    order: -1;
    margin-bottom: 40px;
  }

  .bonus-title {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .bonus-offer-section {
    padding: 60px 0;
  }

  .bonus-title {
    font-size: 1.8rem;
  }

  .bonus-description {
    font-size: 1rem;
  }

  .popup-container {
    padding: 30px 20px;
  }

  .popup-title {
    font-size: 1.5rem;
  }
}
