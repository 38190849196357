.caseStudyContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow-x: hidden;
  column-gap: 40px;
  row-gap: 60px;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    grid-template-columns: auto;
    gap: 20px;
  }
}

.titleContainer {
  padding-top: 60px;
  color: #303030;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 40px;
}

.caseStudyBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.caseStudyImages {
  border-radius: 4px;
  max-width: 100%;
  height: 250px;
  object-fit: contain;
  width: 100%;
  border: 1px solid #a1c9e5;
}

.subtitleText {
  color: #303030;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
}

.description {
  color: #606060;
  line-height: 1.75;
  font-size: 16px;
  font-weight: 400;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.publishDate {
  color: #909090;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
}

.mediaRedirectBtn {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  margin-top: 12px;
}

.mediaRedirectBtn .readMoreText {
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
}

.mediaRedirectBtn > img {
  width: 16px;
  height: 16px;
}
