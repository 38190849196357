/* .section {
  width: 100%;
  display: flex;
  transition: 2s;
  align-items: center;
  justify-content: center;
 

  padding: 150px 0;
} */

.gatewaySection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gatewaySection > .gatewayTitle {
  color: #303030;
  text-align: center;
  letter-spacing: -1.28px;
  font-size: 40px;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 8px;
}

.gatewaySection > .gatewayText {
  color: #303030;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  width: 80%;
  margin: auto;
  margin-bottom: 16px;
}

.gatewayBtn {
  cursor: pointer;
  width: max-content;
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
}

.gatewayBtn > a {
  color: #fff;
  font-weight: 700;
  line-height: normal;
  font-size: 20px;
}

.gatewayBtn > img {
  width: 16px;
}

.gatewayTitle > img {
  height: 24px;
}

.tab-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.tab-icon > img {
  width: 32px;
}

.tab-name {
  color: #909090;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  transition: font-weight 300ms ease-in;
  margin: auto;
  line-height: 16px;
}

.react-tabs__tab:not(.react-tabs__tab--selected):hover .tab-name {
  transform: translateY(-10px);
  font-weight: 700;
}

.react-tabs__tab:not(.react-tabs__tab--selected):hover .tab-icon {
  transform: translateY(-10px);
  font-weight: 700;
}

.react-tabs__tab--selected {
  background-color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: relative;
  box-shadow: 0px 10px 10px 4px #00000015;
}

.react-tabs__tab--selected::after,
.react-tabs__tab--selected::before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  background-color: transparent;
  bottom: 0px;
  animation-delay: 300ms;
  animation: fade 500ms ease-in-out;
}

.react-tabs__tab--selected::after {
  right: -12px;
  border-bottom-left-radius: 16px;
  box-shadow: -6px 6px 0 6px #fff;
}

.react-tabs__tab--selected::before {
  left: -12px;
  border-bottom-right-radius: 16px;
  box-shadow: 6px 6px 0 6px #fff;
}

.react-tabs__tab--selected .tab-name {
  color: #303030;
  font-weight: 600;
}

.react-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

/* Basic styling for the tab list */
.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  overflow: hidden;
  list-style-type: none;
  margin-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.image-section {
  background: #fff;
  border-radius: 16px;
}

/* Styling individual tabs */
.react-tabs__tab-list > .react-tabs__tab {
  padding: 0.5rem;
  min-width: 120px;
  font-size: 12px;
  transition: all 300ms ease-in;
  cursor: pointer;
  outline: unset;
  margin-right: 0;
}

.react-tabs__tab-list > .react-tabs__tab .tab-icon {
  transition: all 300ms ease-in;
}
.react-tabs__tab-list > .react-tabs__tab .tab-name {
  transition: all 300ms ease-in;
}

.react-tabs__tab-list > .react-tabs__tab img {
  filter: grayscale(1);
}

.react-tabs__tab-list > .react-tabs__tab--selected .tab-icon img {
  filter: grayscale(0) !important;
}

.react-tabs__tab-panel--selected {
  background: #fff;
  transition: all 300ms ease-in;
  border-radius: 20px;
  padding: 16px;
  max-width: 100%;
  width: --webkit-fill-available;
  margin: auto;
  box-shadow: 0px 0px 15px 12px #00000015;
}

.fade-in {
  animation: fade 1s ease-in-out;
}

.section-btn {
  display: flex;
  gap: 16px;
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6));
  background-clip: text;
  align-items: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.text-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.display-section {
  display: grid;
  gap: 16px;
}

.section-btn > button {
  all: unset;
  cursor: pointer;
}

.section-btn > img {
  width: 16px;
  height: 16px;
}

.text-section ul > li {
  margin: 0;
}

.image-section img {
  width: 100%; /* Make image take full width of its container */
  height: 100%; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure image doesn’t overflow */
}

.privateLabelTab .metal-cloud-container {
  grid-template-columns: 1fr 4fr 1fr;
}

.metal-cloud-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 24px;
}

.displayBoxes {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  background: #e8f2f8;
  padding: 16px 32px;
  box-sizing: border-box;
}

.displayBoxes > .displayContent {
  margin: auto;
}

.displayBoxes > img {
  width: 60px;
}

.marketPlaceGrid {
  display: none;
}

.equallyDistributedGrid {
  display: none;
}

.displayBoxes > p {
  line-height: normal;
  width: 95%;
  font-weight: 400;
  color: #303030;
  text-wrap: balance;
  margin: 0;
  font-size: 16px;
}

.displayBoxes strong {
  font-size: inherit;
  line-height: inherit;
}

.mid-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
}

.mid-section > .mid-header {
  color: #000;
  text-align: center;
  text-wrap: balance;
  font-size: 20px;
  line-height: normal;
  font-weight: 400;
}

.mid-header > strong {
  font-size: inherit;
  line-height: normal;
}

.mainBanner {
  overflow: hidden;
  padding-bottom: 0 !important;
  padding-top: 225px !important;
}
.bannerLeft h2 span.skyText {
  font-size: 64px;
  font-weight: 700;
  color: #1579be;
  line-height: 74px;
}
.marketbnr {
  padding-top: 190px;
}
.marketbnr {
  background-color: #e8f2f8;

  position: relative;
}

.mainBanner {
  overflow: hidden;
  padding-bottom: 0 !important;
}
.mainBanner .overflowSection {
  height: auto;
}
.overflowSection {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 2s;
}

.row {
  display: flex;

  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.bannerLeft h2 {
  line-height: 84px;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .bannerLeft h2,
  .sechead h3,
  .sechead h3 span {
    font-size: 50px;
    line-height: 74px;
  }
}

@media (max-width: 1366px) and (max-height: 768px) {
  .titleText,
  .titleText .skyText,
  .bannerLeft h2 {
    line-height: 1.2;
  }
}

@media only screen and (max-width: 991px) {
  .bannerLeft h2.titleText {
    font-size: 36px !important;
  }
}

@media (max-width: 575px) {
  .bannerLeft h2,
  .bannerLeft h2.titleText,
  .bannerLeft .skyText {
    font-size: 30px !important;
    line-height: 48px !important;
  }
}

@media (max-width: 575px) {
  .titleText {
    margin: 0 0 20px;
  }
}

@media (max-width: 575px) {
  .titleText,
  .titleText .skyText {
    font-size: inherit;
    line-height: inherit;
  }
}

.bannerLeft p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  color: #606060;
}
p:last-child {
  margin-bottom: 0 !important;
}
.bannerRight {
  top: -13px;
  z-index: 5;
  padding: 10px;
  position: relative;
}
.bannerPoly {
  text-align: right;
  position: relative;
}
.bannerPoly img {
  height: 100%;
  width: 100%;
}
.img-fluid {
  max-width: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}
.triangleTopLeftBg {
  width: 100%;
  position: relative;
  background-color: #d0e4f2;
  padding-top: 380px;
  padding-bottom: 200px;
}
@media (max-width: 1600px) {
  .triangleTopLeftBg {
    padding-top: 270px;
    padding-bottom: 120px;
    width: 100%;
    position: relative;
    background-color: #d0e4f2;
  }
}

.col-lg-12 {
  /* flex: 0 0 100%;
  max-width: 100%; */
  width: 100%;
}
h2 .titleText {
  line-height: 1.2;
  font-size: 50px;
  margin: 0 0 35px;
  font-weight: 400;
  color: #303030;
}
.titleText {
  line-height: 1.2;
}
.titleText .skyText {
  font-size: 60px;
}
@media (max-width: 1600px) {
  .titleText .skyText {
    font-size: 50px;
  }
}

.triangleTopLeftBg:before {
  top: -1px;
  left: 0;
  right: 0;
  z-index: 3;
  content: "";
  width: 100%;
  height: 400px;
  position: absolute;
  transform: rotateZ(180deg);
  background-color: rgb(232 242 248);
  clip-path: polygon(0 0, 0 100%, 100% 100%);
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.businessSectionWrap .aosAnim.ltr .imgBoxWrap {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}
.companySection {
  padding-top: 40px;
  padding-bottom: 80px;
}

@keyframes from-right-in {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
@media (min-width: 768px) and (max-width: 990px) {
  .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 1440px) {
  .triangleTopLeftBg {
    padding-top: 200px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 1024px) {
  .triangleTopLeftBg {
    padding-top: 170px;
    padding-bottom: 60px !important;
  }
}

@media only screen and (max-width: 768px) {
  .triangleTopLeftBg {
    padding-top: 110px;
  }
}

@media (max-width: 1440px) {
  .triangleTopLeftBg:before {
    height: 330px;
  }
}

@media only screen and (max-width: 991px) {
  .triangleTopLeftBg:before {
    height: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .triangleTopLeftBg:before {
    height: 110px;
  }
}
@media (max-width: 1600px) {
  .titleText {
    font-size: 50px;
    margin: 0 0 35px;
  }
  .companySection {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}
@media (max-width: 1440px) {
  .titleText,
  .titleText .skyText {
    font-size: 40px;
    line-height: 1.5;
  }
  .companySection {
    padding: 20px 0;
  }
  .companySection .rfm-child {
    height: 80px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .titleText,
  .titleText .skyText,
  .bannerLeft h2 {
    line-height: 1.2;
  }
}
@media (max-width: 1280px) {
  .titleText {
    font-size: 40px;
    line-height: 65px;
  }
}
@media only screen and (max-width: 1024px) {
  .bannerLeft h2,
  .titleText,
  .titleText .skyText {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 575px) {
  .titleText,
  .titleText .skyText {
    font-size: 28px !important;
    line-height: 40px;
  }
  .companySection {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .titleText,
  .titleText .skyText {
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 768px) {
  .triangleTopLeftBg {
    padding-top: 110px;
  }
}
@media only screen and (max-width: 1024px) {
  .bannerLeft h2 {
    font-size: 40px !important;
    line-height: 50px;
  }
}

@media only screen and (max-width: 1268px) {
  .gatewaySection > .gatewayTitle {
    font-size: 32px;
  }
  .displayBoxes > img {
    width: 48px;
  }

  .displayBoxes p br {
    display: none;
  }
  .companySection {
    padding: 20px 0;
  }
  .companySection .rfm-marquee-container {
    height: 60px;
  }
}

@media only screen and (max-width: 1024px) {
  .gatewayTitle > img {
    height: 18px;
  }

  .react-tabs__tab-list {
    overflow: scroll;
  }

  .companySection .rfm-child {
    height: 50px;
  }

  .displayBoxes > p {
    width: 70%;
  }

  .react-tabs__tab-panel--selected {
    padding: 16px;
    border-radius: 8px;
  }

  .grid-container {
    grid-template-columns: auto;
  }

  .marketPlaceGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .equallyDistributedGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .metal-cloud-container {
    display: flex;
    flex-direction: column;
  }

  .financing-container {
    grid-template-columns: auto;
  }

  .display-section {
    display: none;
  }

  .displayBoxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 8px 16px;
    gap: 8px;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .displayBoxes > p {
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .react-tabs__tab-list {
    overflow: hidden;
    width: 75%;
    justify-content: unset;
  }

  .react-tabs__tab-panel-mobile1 {
    border-top-left-radius: 0;
  }
  .react-tabs__tab-panel-mobile2 {
    border-top-right-radius: 0;
  }

  .react-tabs__tab-list {
    padding-left: 0px;
    padding-right: 0px;
  }

  .displayBoxes p br {
    display: block;
  }
  .react-tabs__tab-panel--selected {
    width: 75%;
  }
  .tab-name {
    font-size: 14px;
  }
  .displayBoxes > img {
    width: 36px;
  }
  .gatewaySection > .gatewayText {
    width: 100%;
  }
}

@media only screen and (max-width: 540px) {
  .react-tabs__tab-panel--selected {
    width: 50%;
  }
  .react-tabs__tab-list {
    width: 50%;
  }
  .tab-name {
    font-size: 12px;
  }
  .gatewaySection > .gatewayText {
    font-size: 13.75px;
  }
  .displayBoxes > img {
    width: 24px;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
