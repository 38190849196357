.stripheader {
  padding: 4px 0;
  background: #fff;
  backdrop-filter: blur(5px);
  white-space: nowrap;
  display: block;
  width: 100%;
}

.stripP {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.stripP strong {
  font-size: 14px;
  line-height: 20px;
  padding: 0 5px;
}

.stripP img {
  width: 20px;
  margin: 0 15px;
}

.stripP a {
  font-size: 14px !important;
  line-height: 20px;
  color: #1579be;
  font-weight: 600;
}

@media (max-width: 1440px) {
  .stripP,
  .stripP strong,
  .stripP a {
    font-size: 12px !important;
  }

  .stripP img {
    width: 15px;
    margin: 0 10px;
  }
}

@media (max-width: 500px) {
  .stripP,
  .stripP strong,
  .stripP a {
    font-size: 9px !important;
  }
  .stripP img {
    width: 12px;
    margin: 0 5px;
  }
  .stripP strong {
    padding: 0 2px;
  }
}
