.marketbnr {
  padding-top: 200px !important;
  position: relative;
  background-color: #e8f2f8;
  display: block;
}
@media (max-width: 1600px) {
  .marketbnr {
    padding-top: 140px !important;
    position: relative;
    background-color: #e8f2f8;
    display: block;
  }
}
@media (max-width: 1440px) {
  .marketbnr {
    padding-top: 120px !important;
  }
}
@media (max-width: 1280px) {
  .mainBanner {
    padding-top: 170px !important;
  }
}
@media only screen and (max-width: 768px) {
  .innerBanner {
    padding: 120px 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .marketbnr {
    padding: 50px 0;
    padding-bottom: 0;
    display: block;
    padding-top: 120px !important;
  }
}
.homebnr.marketbnr {
  padding-top: 325px !important;
}
@media (max-width: 1600px) {
  .homebnr.marketbnr {
    padding-top: 190px !important;
  }
}
@media (max-width: 1440px) {
  .homebnr.marketbnr {
    padding-top: 160px !important;
  }
}

@media (max-width: 1280px) {
  .mainBanner {
    padding-top: 170px !important;
  }
}
@media only screen and (max-width: 768px) {
  .innerBanner {
    padding-top: 120px !important;
    padding-bottom: 50px !important;
  }
}
@media only screen and (max-width: 768px) {
  .homebnr.marketbnr {
    padding-top: 160px !important;
  }
}

.overflowSection {
  /* height: 200vh; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 2s;
}
.bannerContent.aosAnim .leftBannerCont {
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
@keyframes fade-slide-in-ltr {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.col-md-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-7 {
  flex: 0 0 100%;
  max-width: 100%;
}
.bannerLeft h2 {
  font-size: 50px;
  line-height: 74px;
  font-weight: 400;
  margin-bottom: 30px;
}
.bannerLeft h2 {
  line-height: 84px;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .bannerLeft h2,
  .sechead h3,
  .sechead h3 span {
    font-size: 50px;
    line-height: 74px;
  }
}

@media (max-width: 1366px) and (max-height: 768px) {
  .titleText,
  .titleText .skyText,
  .bannerLeft h2 {
    line-height: 1.2;
  }
}

@media only screen and (max-width: 991px) {
  .bannerLeft h2.titleText {
    font-size: 36px !important;
  }
}

@media (max-width: 575px) {
  .bannerLeft h2,
  .bannerLeft h2.titleText,
  .bannerLeft .skyText {
    font-size: 30px !important;
    line-height: 48px !important;
  }
}

@media (max-width: 575px) {
  .titleText {
    margin: 0 0 20px;
  }
}

@media (max-width: 575px) {
  .titleText,
  .titleText .skyText {
    font-size: inherit;
    line-height: inherit;
  }
}
.bannerLeft p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  margin-bottom: 16px;
  color: #606060;
}

.npButton {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  padding: 15px 30px;
  border-radius: 4px;
  line-height: 24px;
  display: inline-block;
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
  transition: 0.4s;
}
.npButton:hover {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(180deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
}
.col-md-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.bannerContent.aosAnim .bannerRight {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}
.bannerRight {
  top: -13px;
  z-index: 5;
  padding: 10px;
  position: relative;
}
@keyframes from-right-in {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.bannerPoly {
  text-align: right;
  position: relative;
  text-align: right;
}
.bannerPoly1 {
  width: 525px;
  height: 560px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transform: rotate(-20deg);
}
/* @media (min-width: 90px) {
  .bannerPoly1 {
    width: 100%;
    height: 100%;
  }
} */
@media (max-width: 990px) {
  .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bannerPoly1 {
    width: 100%;
    height: 100%;
  }
}

.bannerPoly img {
  width: 100%;
}
.bannerPoly1 > img {
  height: 100%;
  transform: rotate(20deg) scale(1.5);
}
.bannerPoly1 .video {
  cursor: pointer;
  opacity: 0;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: rotate(20deg);
  transition: 0.4s;
  overflow-clip-margin: content-box;
  overflow: clip;
}
.bannerPoly a {
  padding: 55px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
}
@media (max-width: 1600px) {
  .bannerPoly a {
    padding: 30px;
    width: 100px;
    height: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .bannerPoly a {
    padding: 24px;
    width: 72px;
    height: 72px;
  }
}
.bannerPoly1 a {
  transform: translate(-50%, -50%) rotate(20deg) !important;
}
.bannerPoly img {
  width: 100%;
  height: 100%;
}

.addr.aosAnim.ltr {
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
.addr {
  background-image: linear-gradient(
    to right,
    #d0e4f2,
    #d0e4f2,
    #d0e4f200,
    #d0e4f200,
    #d0e4f2
  );
  padding: 25px 0;
  width: 100%;
}
@media (max-width: 575px) {
  .addr {
    margin-bottom: 24px;
    padding: 1px 0px;
  }
}
.eachLoc h4 {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: #1579be;
  margin: 0 24px;
}
@media only screen and (max-width: 1024px) {
  .eachLoc h4 {
    font-size: 18px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .eachLoc h4 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .eachLoc h4 {
    font-size: 12px;
    line-height: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .eachLoc h4 {
    margin: 0 8px;
  }
}
.eachLoc:not(:last-child) h4:after {
  top: 50%;
  content: "";
  width: 2px;
  height: 40px;
  position: absolute;
  right: -24px;
  background-color: #73afd8;
  transform: translateY(-50%);
}
@media only screen and (max-width: 768px) {
  .eachLoc:not(:last-child) h4:after {
    height: 20px;
    right: -8px;
  }
}
.align-items-center {
  align-items: center !important;
}
.d-flex {
  display: flex !important;
}
.triangleTopLeftBg {
  width: 100%;
  position: relative;
}
.keyNumber {
  /* padding-bottom: 400px; */
  background-color: #d0e4f2;
}
.col-lg-12 {
  position: relative;
  width: 100%;
  /* flex: 0 0 100%;
  max-width: 100%; */
  padding-right: 15px;
  padding-left: 15px;
}
.triangleTopLeftBg .sechead {
  margin-bottom: 50px;
  position: relative;
}
.keyNumber .aosAnim.ltr h3,
.ltr.sechead h3 {
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
.sechead h3,
.sechead h3 span {
  font-size: 50px !important;
  line-height: 74px;
  font-weight: 400;
}
.sechead h3 span {
  font-size: 64px !important;
  font-weight: 700;
  color: #1579be;
}
.keyNumber .aosAnim.ltr .keyWrap {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}
.keyWrap {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@keyframes from-right-in {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.keyNumber .eachkey {
  margin-bottom: 30px;
  width: calc(25% - 20px);
  border-radius: 20px;
  background-color: #fff;
  padding: 32px 20px;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
/* .keyNumber .eachkey h4,.eachkey h4 span {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 8px;
} */
.eachkey h6 {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
}

.sechead h3 span {
  font-size: 50px !important;
  line-height: 74px;
}
@media only screen and (max-width: 768px) {
  .addr::before {
    position: absolute;
    content: "";
    left: -50%;
    top: 0;
    height: 100%;
    width: 50%;
    background-color: #d0e4f2;
  }
}
@media only screen and (max-width: 768px) {
  .locIcon img {
    width: 28px;
  }
}
/*responsiveness*/

@media (min-width: 992px) {
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 1440px) {
  .sechead h3,
  .sechead h3 span {
    font-size: 40px !important;
    line-height: 1.4;
  }
}
@media only screen and (max-width: 1024px) {
  .sechead h3,
  .sechead h3 span {
    font-size: 36px !important;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .sechead h3,
  .sechead h3 span {
    font-size: 28px !important;
  }
}
@media only screen and (max-width: 768px) {
  .sechead h3,
  .sechead h3 span {
    font-size: 28px !important;
    line-height: 40px;
  }
}
@media (max-width: 1920px) {
  .keyNumber .eachkey {
    width: calc(25% - 20px);
    border-radius: 20px;
    background-color: #fff;
    padding: 32px 20px;
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 2800px) {
  .keyNumber .eachkey {
    border-radius: 20px;
    background-color: #fff;
    padding: 32px 20px;
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 1600px) {
  .keyNumber .eachkey {
    margin-bottom: 30px;
  }
}
@media (max-width: 1200px) {
  .eachkey,
  .keyNumber .eachkey {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 768px) {
  .keyNumber .eachkey {
    width: calc(50% - 13px);
    padding: 10px;
    min-height: inherit;
    margin-bottom: 12px;
    border-radius: 7px;
  }
}
@media (max-width: 575px) {
  .keyNumber .eachkey {
    width: calc(50% - 7px);
  }
}
@media (max-width: 1440px) {
  .eachkey h4,
  .keyNumber .eachkey h4 {
    font-size: 24px;
    line-height: 32px;
  }
}
.eachkey h4,
.eachkey h4 span {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 8px;
}
@media (max-width: 1600px) {
  .keyNumber .eachkey h4,
  .keyNumber .eachkey h4 span {
    font-size: 32px;
    margin-bottom: 8px;
    font-weight: 700;
    line-height: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .keyNumber .eachkey h4,
  .keyNumber .eachkey h4 span {
    font-size: 28px;
  }
}
@media only screen and (max-width: 768px) {
  .keyNumber .eachkey h4,
  .keyNumber .eachkey h4 span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 800;
  }
}
@media only screen and (max-width: 768px) {
  .keyNumber .eachkey h4 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 800;
    margin-bottom: 8px;
  }
}

.bannerPoly1:hover .video {
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .addr {
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .addr {
    margin-bottom: 24px;
    padding: 13px 20px;
  }
}
@media (max-width: 1200px) {
  .addr {
    margin-top: 30px;
  }
}
@media (max-width: 768px) {
  .addr2 {
    display: none;
  }
}
@media (min-width: 769px) {
  .addr1 {
    display: none;
  }
}
