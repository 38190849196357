.rs-modal-header .rs-modal-header-close {
  top: 22px;
}

.rs-form-control-label span {
  position: relative;
  color: #303030;
  font-size: 16px;
  font-weight: 700;
}

.rs-form-group .rs-form-control-label span::after {
  content: "*";
  position: absolute;
  top: -10px;
  right: -12px;
  color: #e43e2b;
}

.rs-checkbox-wrapper {
  left: 4px;
}

.rs-picker-default
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-indicator
  .rs-picker-caret-icon {
  top: 14px;
}

.rs-picker-default
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-indicator
  .rs-picker-clean {
  top: 14px;
  font-size: 12px;
}
.rs-picker-select-menu-item {
  font-size: 14px;
}

.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
  margin-bottom: 16px;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  font-size: 14px;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  font-size: 14px;
}

.rs-checkbox-wrapper .rs-checkbox-inner:after,
.rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-wrapper:before {
  height: 24px;
  width: 24px;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:after {
  margin-top: 6px;
  margin-left: 9px;
}

.rs-checkbox label {
  font-size: 14px;
  color: #303030;
  font-weight: 700;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before {
  background-color: #1579be;
}

.rs-btn-subtle {
  color: #3877b9;
  border-radius: 4px;
  border: 1px solid #1579be;
  background: #fff;
  font-weight: 700;
}

.rs-btn-default {
  background-color: #1579be;
  color: #fff;
  border: 1px solid #1579be;
  font-weight: 700;
}

.rs-btn-subtle:hover {
  background-color: #1579be;
  color: #fff;
}

.rs-btn-default:hover {
  background-color: #fff;
  color: #3877b9;
}

.rs-btn-default:disabled,
.rs-btn-default:disabled:hover {
  border-color: #ccc;
  background-color: #f5f5f5; /* Or any color for the disabled state */
  color: #ccc; /* Greyed-out text for disabled buttons */
  cursor: not-allowed; /* Indicate that the button is not clickable */
}

.rs-btn svg {
  margin-right: 6px;
}

.rs-modal-title {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .rs-modal-title {
    font-size: 20px;
  }
  .rs-modal-header .rs-modal-header-close {
    top: 21px;
  }
}
