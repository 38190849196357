.teamManagementC {
  background-color: #e8f2f8;
}
.cManagement.aosAnim.ltr {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}
.eachCM {
  width: calc(33.33%);
  margin-right: 80px;
}
.cManagement .eachCM:last-child {
  margin-right: 0;
}
.mImageWrap {
  background-color: #a1c9e5;
  border-radius: 16px;
  margin-bottom: 24px;
}
.mImageWrap img {
  width: 100%;
}
.mngDetails h4 {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
}

.mngDetails h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  margin-bottom: 0;
}

.mngDetails a:hover {
  opacity: 1;
}

.mngDetails a {
  opacity: 0.4;
}@media only screen and (max-width: 768px) {.sechead h3 span {
  font-size: 28px !important;
  line-height: 40px;
}}
@media only screen and (max-width: 768px) {.teamManagementC {
  padding-bottom: 28px !important;
}}@media (max-width: 1366px) and (max-height: 768px){ .sechead {
  margin-bottom: 40px;}
}@media only screen and (max-width: 768px) {.sechead {
  margin-bottom: 16px;}
}@media only screen and (max-width: 768px) {.sechead h3 span {
  font-size: 28px !important;
  line-height: 40px;}
}@media only screen and (max-width: 768px) {.cManagement {
  flex-direction: column;}
}@media only screen and (max-width: 1024px) {.eachCM {
  width: calc(33.33% - 40px);
  margin-right: 40px;}
}@media only screen and (max-width: 768px){ .eachCM {
  margin-bottom: 32px;}
}@media only screen and (max-width: 768px) {.eachCM {
  width: 100%;}
}@media (max-width: 1366px) and (max-height: 768px) {.mngDetails h4 {
  font-size: 24px;
  line-height: 36px;}
}@media only screen and (max-width: 1024px) {.mngDetails h4 {
  font-size: 24px;}
}@media only screen and (max-width: 768px){ .mngDetails h4 {
  font-size: 16px;
  line-height: 20px;}
}@media (max-width: 1366px) and (max-height: 768px) {.mngDetails h5 {
  font-size: 18px;
  line-height: 34px;}
}@media only screen and (max-width: 1024px){ .mngDetails h5 {
  font-size: 18px;}
}@media only screen and (max-width: 768px) {.mngDetails h5 {
  font-size: 12px;
  line-height: 23px;
}} .cManagement.aosAnim.ltr{
   opacity: 0;
   animation: from-right-in 0.8s linear forwards;
 }
 @keyframes from-right-in {
  0% {
      opacity: 0;
      transform: translateX(20%);
    }
  
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
 }