/* .section {
    width: 100%;
    display: flex;
    transition: 2s;
    align-items: center;
    justify-content: center;
   
} */
.procureSectionWrap {
    padding-top: 130px !important;
    padding-bottom: 120px !important;
    /* overflow: hidden; */
}
.overflowSection {
    /* height: 200vh; */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 2s;
}
.col-lg-12 {
    /* -ms-flex: 0 0 100%; */
    /* flex: 0 0 100%;
    max-width: 100%; */
   
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
       
}
.procureSectionWrap .eachBoxWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.procureSectionWrap .eachBoxWrap .eachBox {
    margin-bottom: 80px;
    width: calc(25% - 30px);
}
.procureSectionWrap .eachBoxWrap .eachBox img {
    margin-bottom: 45px;
    display: inline-block;
}
.procureSectionWrap .eachBoxWrap .eachBox h4 {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 15px;
    color: black;
    font-weight: 700;
}.procureSectionWrap .eachBox p{
    font-size: 20px;
    font-weight: 500;
    line-height: 38px;
    color: #606060;margin-top: 0;
}@media (max-width:1600px){
.procureSectionWrap .eachBox p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
        margin-top: 0;
        color: #606060;
}}
p:last-child {
    margin-bottom: 0 !important;
}@media (max-width: 1280px){ .procureSectionWrap .eachBoxWrap .eachBox {
    margin-bottom: 60px;}
}@media (min-width: 768px) and (max-width: 990px) {.procureSectionWrap .eachBoxWrap .eachBox {
    margin-bottom: 80px;
    width: calc(50% - 30px);}
}@media only screen and (max-width: 768px) {.procureSectionWrap .eachBoxWrap .eachBox {
    width: calc(50% - 20px);}
}@media (max-width: 575px) {.procureSectionWrap .eachBoxWrap .eachBox {
    width: calc(50% - 10px);
    margin-bottom:20px;}
}@media (max-width: 1600px){ .procureSectionWrap .eachBox p {
    font-size: 14px;
    line-height: 24px;}
}@media (max-width: 575px){ .procureSectionWrap .eachBoxWrap .eachBox p {
    font-size: 12px;
    line-height: 23px;}
}@media (max-width: 1440px){ .procureSectionWrap .eachBoxWrap .eachBox h4 {
    font-size: 22px;
    line-height: 32px;margin-bottom: 15px;}
}@media (max-width: 575px) {.procureSectionWrap .eachBoxWrap .eachBox h4 {
    font-size: 16px;
    line-height: 20px;margin-bottom: 15px;
}}.section .aosAnim.ltr .eachBox:nth-child(1),
.section.ltr .eachMediaBox:nth-child(1),
.section.ltr .eachMediaBoxAcc:nth-child(1),
.trWrap.ltr .trBox:nth-child(1),
.section.ltr .sollutionWrap,
.section.ltr .priceSecWrap,
.glimpsRowWrap.ltr,
.eventMWrap.ltr,
.eventFull.ltr,
.csrContent.ltr,
.addressBoxWrap.ltr,
.siteMapRow.ltr,
.casSecM .listContBox.aosAnim {
    opacity: 0;
    animation: up 0.8s linear forwards;}
    @keyframes up {
        0% {
                opacity: 0;
                transform: translateY(300px);
            }
        
            100% {
                opacity: 1;
                transform: translateX(0%);
            }
    }.section .aosAnim.ltr .eachBox:nth-child(2),
    .section.ltr .eachMediaBox:nth-child(2),
    .section.ltr .eachMediaBoxAcc:nth-child(2),
    .trWrap.ltr .trBox:nth-child(2) {
        opacity: 0;
        animation: up 1.1s linear forwards;
    }.section .aosAnim.ltr .eachBox:nth-child(3),
    .section.ltr .eachMediaBox:nth-child(3),
    .section.ltr .eachMediaBoxAcc:nth-child(3),
    .trWrap.ltr .trBox:nth-child(3) {
        opacity: 0;
        animation: up 1.3s linear forwards;
    }.section .aosAnim.ltr .eachBox:nth-child(4),
    .section.ltr .eachMediaBox:nth-child(4),
    .section.ltr .eachMediaBoxAcc:nth-child(4),
    .trWrap.ltr .trBox:nth-child(4) {
        opacity: 0;
        animation: up 1.5s linear forwards;
    }.section .aosAnim.ltr .eachBox:nth-child(5),
    .trWrap.ltr .trBox:nth-child(5) {
        opacity: 0;
        animation: up 1.7s linear forwards;
    }
    
    .section .aosAnim.ltr .eachBox:nth-child(6),
    .trWrap.ltr .trBox:nth-child(6) {
        opacity: 0;
        animation: up 1.9s linear forwards;
    }
    
    .section .aosAnim.ltr .eachBox:nth-child(7),
    .trWrap.ltr .trBox:nth-child(7) {
        opacity: 0;
        animation: up 2.1s linear forwards;
    }
    
    .section .aosAnim.ltr .eachBox:nth-child(8),
    .trWrap.ltr .trBox:nth-child(8) {
        opacity: 0;
        animation: up 2.3s linear forwards;
    }
    
    .section .aosAnim.ltr .eachBox:nth-child(9) {
        opacity: 0;
        animation: up 2.5s linear forwards;
    }