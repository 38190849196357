.mapSec {
  padding-top: 130px !important;
  padding-bottom: 170px !important;
  background-color: #e8f2f8;
}@media (max-width: 1280px) {.mapSec {
  padding-top: 60px!important;}
}@media (max-width: 575px) {.mapSec {
  padding-bottom: 60px !important;
}
}

.mapRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.section.ltr .mapleft {
  width: calc(100% - 760px);
  padding-right: 30px;
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
.mapleft {
    width: calc(100% - 760px);
    padding-right: 30px;
}@media (max-width: 1440px) {.section.ltr .mapleft {
  width: calc(60%);}
}@media only screen and (max-width: 1024px){.section.ltr .mapleft {
  width: 100%;
}}


.mapSec.aosAnim h3 {
  opacity: 1;
  animation: none;
  margin-bottom: 8px;
}
.stateWrap {
  margin-bottom: 40px;
}
.eachstate h4 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
  margin-bottom: 8px;
}
.eachstate h4 span {
  font-size: 40px !important;
  line-height: 50px;
  font-weight: 700;
}
.section.ltr .mapRight {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}
.mapRight {
  width: 730px;
}
.indMapWrap {
  position: relative;
}
.indMapWrap .mapPointers {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.eachPointer {
  position: absolute;
  z-index: 3;
  transition: 0.6s;
}
.ep_1 {
  left: 32%;
  top: 22%;
}
.ep_2 {

  left: 32%;
  top: 30%;
}
.ep_3 {
  
  left: 40%;
  top: 35%;
}
.ep_4 {
 
  left: 13%;
  top: 36%;
}
.ep_5 {

  left: 62%;
  top: 44%;
}.ep_6 {
  
    left: 58%;
    top: 49%;
}.ep_7 {
  
    left: 2%;
    top: 45%;
}
.ep_8 {
   
    left: 8%;
    top: 50%;
}.ep_9 {
  
    left: 18%;
    top: 58%;
}.ep_10 {
 
    left: 29%;
    top: 56%;
}.ep_11 {
    
    left: 40%;
    top: 54%;
}.ep_12 {
    
    left: 19%;
    top: 64%;
}.ep_13 {
   
    left: 22%;
    top: 71%;
}.ep_14 {
   
    left: 23%;
    top: 77%;
}.ep_15 {
   
    left: 30%;
    top: 85%;
}
@media (max-width: 1440px) {.mapRow {
  align-items: flex-start;
  justify-content: space-between;
}}
@media (max-width: 1440px) {.mapleft {
  width: calc(60%);}
}@media only screen and (max-width: 1024px) {.mapleft {
  padding-right: 0;
  margin-bottom: 40px;
}}

@media only screen and (max-width: 1024px){ .mapleft {
  width: 100%;}
}@media (max-width: 575px) {.mapleft {
  margin-bottom: 20px;}
}@media (max-width: 1440px) {.mapleft .sechead {
  margin-bottom: 25px;}
}@media (max-width: 1280px) {.mapSec .sechead {
  margin-bottom: 30px;}
}@media (max-width: 575px) {.mapSec .sechead {
  margin-bottom: 16px;}
}@media (max-width: 1440px) {.sechead h3,
.sechead h3 span {
  font-size: 40px !important;
  line-height: 1.4;}
}@media (min-width: 768px) and (max-width: 990px) {.sechead h3,
.sechead h3 span {
  font-size: 28px !important;}
}

@media only screen and (max-width: 1024px) {.sechead h3,
.sechead h3 span {
  font-size: 36px }
}@media only screen and (max-width: 768px) {.sechead h3 {
  font-size: 28px !important;
  line-height: 40px;
}}@media (max-width: 575px) {.mapSec {
  padding-bottom: 60px !important;
}}@media (max-width: 1440px) {
  .eachstate h4 span {
    font-size: 32px !important;
  }
}@media only screen and (max-width: 768px){ .eachstate h4 span {
  font-size: 20px !important;
  line-height: 25px;
}}

@media only screen and (max-width: 768px){ .eachstate h4 {
  font-size: 20px;
  line-height: 25px;
}}

@media (max-width: 1280px) {.stateWrap {
  margin-bottom: 20px;
}}@media (max-width: 575px) {
  .stateWrap {
    margin-bottom: 16px;
  }
}@media only screen and (max-width: 768px){ .sechead h3 span {
  font-size: 28px !important;
  line-height: 40px;}}
  .eachPointer {
    position: absolute;
    z-index: 3;
    transition: 0.6s;
  }
  
  .eachPointer:hover {
    transform: scale(2);
    z-index: 5;
  }@media (max-width: 575px) {.mapSec p {
    font-size: 12px;
    line-height: 23px;
  }}@media (max-width: 575px) { p {
    font-size: 16px;
    line-height: 26px;
  }
  }
  @media (max-width: 1365px) { p {
    font-size: 16px;
  }}
  
  @media (max-width: 1440px) { p {
    font-size: 18px;
    line-height: 1.4;
  }

  }