.justify-content-between {
  justify-content: space-between !important;
}

.trWrap {
  flex-wrap: wrap;
}

.trBox {
  margin-bottom: 80px;
  /* width: calc(25% - 30px); */
}

.trWrap.ltr .trBox:nth-child(1) {
  opacity: 0;
  animation: up 0.8s linear forwards;
}
.trustRow .sechead {
  margin-bottom: 60px;
  position: relative;
  /* transform: translateX(-100%); */
}
@keyframes up {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.trBox img {
  margin-bottom: 15px;
}

.trBox h5 {
  font-size: 28px;
  color: #000000;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 8px;
}

p:last-child {
  margin-bottom: 0 !important;
}

p {
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  color: #606060;
}
/* @media (max-width:1600px) {p
  {font-size: 16px;
}} */
@media (max-width: 1440px) {
  p {
    font-size: 18px;
    line-height: 1.4;
  }
}
@media (max-height: 705px){ p {
  font-size: 14px;}
}
@media (max-width: 1600px) {.trust {
  padding-bottom: 90px;
}}@media (max-width: 1280px) {.trust {
  padding-bottom: 60px;}
}@media (max-width: 575px){ .trust {
  padding-bottom: 30px;
}}
@media (max-width: 1440px) {.trBox h5 {
  font-size: 24px;
}}

@media (max-width: 1440px) {.trBox h5 {
  font-size: 22px;
  line-height: 32px;}
}@media (min-width: 768px) and (max-width: 990px) {.trBox h5 {
  font-size: 20px;}
}@media only screen and (max-width: 768px) {.trBox h5 {
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 4px;
}}