.mainBanner {
    overflow: hidden;
}
.mainBanner .overflowSection {
    height: auto;
}
.mainBanner {
    overflow: hidden;
    padding-bottom: 0 !important;
    padding-top: 270px;
}.bannerContent.aosAnim .leftBannerCont,
.aosAnim.ltr h2,
.keyNumber .aosAnim.ltr h3,
.ltr.sechead h3,
.section.ltr .sechead p,
.section.ltr .cultureRow .imgBox,
.addr.aosAnim.ltr,
.aosAnim.titleText,
.testSec.ltr .titleText,
.footerSection .footerBlockLeft.aosAnim.ltr,
.section.ltr .mapleft,
.titleText.aosAnim,
.aosAnim.ltr .founderCont,
.exploreSectionWrap .listWrap.ltr .listMenuWrap,
.testSec.ltr .sechead h3,
.detailsRow.aosAnim .detailsMain,
.detailsRow.aosAnim h2,
.eventHead.ltr,
.tabheadC.ltr {
    opacity: 0;
    animation: fade-slide-in-ltr 0.8s linear forwards;
}
.teamBoxWrap.ltr {
    opacity: 0;
    animation: from-right-in 0.8s linear forwards;
}.bannerLeft .newDiv {
    color: #303030;
    margin-top: 12px;
    margin-bottom: 60px;
    font-size: 28px;
    font-weight: 500;
}
@media (max-width: 1440px) {.bannerLeft .newDiv {
    font-size: 22px;
    margin-bottom: 40px;}
}
@media (max-width: 575px) {.bannerLeft .newDiv {
    font-size: 16px;
}}@media only screen and (max-width: 991px) {.bannerLeft .newDiv {
    margin-bottom: 30px;
}}
.bannerLeft h2 {
    line-height: 84px;
    font-size: 64px;
    font-weight: 400;
    margin-bottom: 30px;
}

@media (max-width: 1600px) {

    .bannerLeft h2,
    .sechead h3,
    .sechead h3 span {
        font-size: 50px;
        line-height: 74px;
    }
}

@media (max-width: 1366px) and (max-height: 768px) {

    .titleText,
    .titleText .skyText,
    .bannerLeft h2 {
        line-height: 1.2;
    }
}

@media only screen and (max-width: 991px) {
    .bannerLeft h2.titleText {
        font-size: 36px !important;
    }
}

@media (max-width: 575px) {

    .bannerLeft h2,
    .bannerLeft h2.titleText,
    .bannerLeft .skyText {
        font-size: 30px !important;
        line-height: 48px !important;
    }
}

@media (max-width: 575px) {
    .titleText {
        margin: 0 0 20px;
    }
}

@media (max-width: 575px) {

    .titleText,
    .titleText .skyText {
        font-size: 28px !important;
        line-height: 40px !important;
    }
}
.bannerRight {
   
    z-index: 5;
    position: relative;
}

.bannerPoly a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
}.col-lg-12 {
width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    /* flex: 0 0 100%;
    max-width: 100%; */
    position: relative;
}.teamBoxWrap.ltr {
    opacity: 0;
    animation: from-right-in 0.8s linear forwards;
}.teamBoxWrap {
    display: flex;
}.teamBoxWrap .slick-list {
    padding: 0 0 0 15px;}
        .slick-list {
            position: relative;
            display: block;
            overflow: hidden;
            margin: 0;
            padding: 0;
        }.slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }.slick-track:before,
        .slick-track:after {
            display: table;
            content: '';
        }
                .slick-slide {
                    display: block;
                    float: left;
                    height: 100%;
                    min-height: 1px;
                }
                                .teamBoxWrap .imgBoxHolder .yearTitle {
                                    display: block;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: var(--thm-black);
                                    position: relative;
                                }.teamBoxWrap .imgBoxHolder .yearTitle:after {
                                    position: absolute;
                                    content: "";
                                    height: 1px;
                                    width: 100%;
                                    background-image: url(../../../assets/images/career/border-Bg.jpg);
                                    background-repeat: repeat-x;
                                    background-position: 0 0;
                                    bottom: -40px;
                                    left: -10px;
                                }.teamBoxWrap .imgEachBoxx {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 8px;
                                    background: #e8f2f8;
                                    margin-top: 80px;
                                    margin-right: 40px;
                                }.teamBoxWrap .imgEachBoxx:before {
                                    width: 40px;
                                    height: 40px;
                                    background: #e8f2f8;
                                    left: -15px;
                                    top: -62px;
                                    position: absolute;
                                        content: "";
                                        border-radius: 50%;
                                }.teamBoxWrap .imgEachBoxx .imgBox {
                                    width: 100px;
                                    height: 100px;
                                }.teamBoxWrap .imgEachBoxx .imgCont {
                                    width: 120px;
                                    height: 100px;
                                    padding: 8px 12px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }.teamBoxWrap .imgEachBoxx .imgCont p {
                                    font-weight: 600;
                                    line-height: 28px;
                                    color: #303030;
                                    font-size: 16px;
                                }.teamBoxWrap .imgEachBoxx:after {
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
    left: -5px;
    top: -52px;
}
/*new*/
.triangleTopLeftBg {
    width: 100%;
    position: relative;
    background-color: #d0e4f2;
   
}.col-lg-12 {
 /* flex: 0 0 100%;
    max-width: 100%;position: relative; */
        width: 100%;
        padding-right: 15px;
        padding-left: 15px
}.teamBoxWrap.ltr {
    opacity: 0;
    animation: from-right-in 0.8s linear forwards;
}.teamBoxWrap {
    display: flex;
}.teamBoxWrap .slick-list {
    padding: 0 0 0 15px;
}.slick-slider .slick-list {
  
    transform: translate3d(0, 0, 0);
}.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}.slick-initialized .slick-slide {
    display: block;float: left;
        height: 100%;
        min-height: 1px;
}.teamBoxWrap .imgBoxHolder .yearTitle {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--thm-black);
    position: relative;
}.teamBoxWrap .imgBoxHolder .yearTitle:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-image: url(../../../assets/images/career/border-Bg.jpg);
    background-repeat: repeat-x;
    background-position: 0 0;
    bottom: -40px;
    left: -10px;
}.teamBoxWrap .imgEachBoxx {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #e8f2f8;
    margin-top: 80px;
    margin-right: 40px;
}.teamBoxWrap .imgEachBoxx:before {
    width: 40px;
    height: 40px;
    background: #e8f2f8;
    left: -15px;
    top: -62px;
}.teamBoxWrap .imgEachBoxx .imgBox {
    width: 100px;
    height: 100px;
}.teamBoxWrap .imgEachBoxx .imgBox img {
    width: 100%;
}.slick-slide img {
    display: block;
}.teamBoxWrap .imgEachBoxx .imgCont {
    width: 120px;
    height: 100px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}.teamBoxWrap .imgEachBoxx .imgCont p {
    font-weight: 600;
    font-size: 16px;
        line-height: 28px;
}.teamBoxWrap .imgEachBoxx:after {
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
    left: -5px;
    top: -52px;
        content: "";
            border-radius: 50%;
}.slick-slide {
    display: block;
    float: left;
    height: 100%;
    min-height: 1px;
}.teamBoxWrap .imgBoxHolder .yearTitle {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--thm-black);
    position: relative;
}.teamBoxWrap .imgBoxHolder .yearTitle:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-image: url(../../../assets/images/career/border-Bg.jpg);
    background-repeat: repeat-x;
    background-position: 0 0;
    bottom: -40px;
    left: -10px;
}.teamBoxWrap .imgEachBoxx {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #e8f2f8;
    margin-top: 80px;
    margin-right: 40px;
}.teamBoxWrap .imgEachBoxx .imgCont {
    width: 120px;
    height: 100px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}.teamBoxWrap .imgEachBoxx .imgCont p {
    font-weight: 600;
    font-size: 16px;line-height: 28px;
}.teamBoxWrap .imgEachBoxx:after {
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
    left: -5px;
    top: -52px;
}.slick-track:after {
    clear: both;display: table;
        content: '';
}

@media (min-width: 992px) {.col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}}@media only screen and (max-width: 768px) {.innerBanner {
    padding: 50px 0 50px;
}}@media (max-width: 575px){ .teamSectionWrap {
    padding-bottom: 80px !important;
}}
.teamBoxWrap .imgEachBoxx .imgCont {
    width: 120px;
    height: 100px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width:1440px) {
    .teamBoxWrap .imgBoxHolder .yearTitle {
        font-size: 14px;
        line-height: 18px;
    }

    .teamBoxWrap .imgEachBoxx:before {
        width: 30px;
        height: 30px;
        top: -43px;
    }

    .teamBoxWrap .imgEachBoxx:after {
        left: -7px;
        width: 15px;
        height: 15px;
        top: -36px;
    }.teamBoxWrap .imgBoxHolder .yearTitle:after {
       bottom: -52px;
}}
@media only screen and (max-width:991px) {
.teamBoxWrap .imgEachBoxx .imgBox {
        width: 40px;
        height: 42px;
    } .teamBoxWrap .imgEachBoxx .imgCont {
         width: calc(100% - 40px);
         height: 42px;
     }.teamBoxWrap .imgEachBoxx .imgCont p {
        font-size: 12px;
        line-height: 15px;
    }.teamBoxWrap .imgEachBoxx {
        margin-right: 20px;
        margin-top: 60px;
    }
        .teamBoxWrap .imgEachBoxx:before {
            top: -42px;
            left: 0px;
            width: 16px;
            height: 16px;
        }.imgEachBoxx .imgBoxEach:after {
             width: 8px;
             height: 8px;
             left: 4px;
             top: -38px;
         }.teamBoxWrap .imgBoxHolder .yearTitle:after {
            bottom: -27px;
            left: 0;
        }.teamBoxWrap .imgEachBoxx:after {
            left: 3px;
            width: 9px;
            height: 9px;
            top: -39px;
        }
}

@media (max-width:575px) {
    .teamBoxWrap .imgBoxHolder .yearTitle {
            font-size: 10px;
            line-height: 13px;
        }
       
        }
