.section1 {
  width: 100%;
  display: flex;
  transition: 2s;
  align-items: center;
  justify-content: center;
  /* padding-top: 120px; */
  padding: 120px 0px 150px;
}
.cultureSectionWrap {
  padding-top: 120px !important;
}
.eachList {
  display: flex;
  align-items: center;
  gap: 12px;
}

.eachList img {
  height: 60px;
}

.scrollSection {
  width: 100%;
  /* height: calc(100vh - 120px); */
  position: relative;
  overflow: hidden;
  transition: 2s;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cultureSectionWrap .cultureRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cultureSectionWrap .imgBox {
  width: 800px;
}
.cultureSectionWrap .contBox {
  width: calc(100% - 800px);
  padding-left: 80px;
  border-radius: 10px;
  margin-left: -70px;
}
@media (max-width: 1600px) {
  .cultureSectionWrap .contBox {
    width: calc(100% - 772px);
    padding-left: 35px;
    border-radius: 10px;
    margin-left: -70px;
  }
}
.section.ltr .contBox h2 {
  opacity: 1;
  animation: none;
}
.cultureSectionWrap .contBox p {
  font-size: 24px;
  line-height: 44px;
  font-weight: 500;
  color: #606060;
  margin-top: 0;
  margin-bottom: 16px;
}
.cultureSectionWrap ul {
  margin-bottom: 35px;
}
.cultureList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cultureSectionWrap .contBox ul li {
  display: flex;
  align-items: center;
}
.cultureSectionWrap .contBox ul li:not(:last-child) {
  margin-bottom: 30px;
}
.cultureSectionWrap .contBox ul li img {
  margin-right: 15px;
  display: inline-block;
}

.knowMoreBtn.gradientBtn {
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);

  -webkit-text-fill-color: transparent;
  background-clip: text;

  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
}
.knowMoreBtn {
  display: inline-block;

  text-align: center;
  color: #fff;
  position: relative;
}
.cultureSectionWrap .contBox ul li strong {
  font-weight: 500;
  font-size: 24px;
}
.knowMoreBtn.gradientBtn:after {
  background-image: url(../../../assets/images//icons/rgt_arrow_vector_bl.png);
}
.cultureSectionWrap ul {
  margin-bottom: 35px;
}
.knowMoreBtn:after {
  content: "";
  display: inline-block;
  transition: 0.6s;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-left: 15px;
  position: relative;
  right: 0;
  top: 2px;
}
.knowMoreBtn.gradientBtn {
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  line-height: 35px;
}
.cultureSectionWrap ul {
  margin-bottom: 35px;
} /*responsive*/
@media (max-width: 1440px) {
  .cultureSectionWrap,
  .mediaSectionWrap {
    padding-top: 90px;
  }
}

@media (max-width: 1440px) {
  .cultureSectionWrap {
    padding-bottom: 120px !important;
  }
}
@media (max-width: 1280px) {
  .cultureSectionWrap {
    padding-bottom: 60px !important;
  }
}

@media (max-width: 1280px) {
  .cultureSectionWrap,
  .mediaSectionWrap {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 1024px) {
  .cultureSectionWrap {
    padding-bottom: 80px !important;
  }
}
@media (max-width: 1280px) {
  .cultureSectionWrap .scrollSection,
  .mediaSectionWrap .scrollSection {
    height: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .scrollSection,
  .mapSec .scrollSection {
    height: auto !important;
  }
}
@media only screen and (max-width: 1100px) {
  .cultureSectionWrap .cultureRow {
    flex-direction: column-reverse;
  }
}

@media (max-width: 1600px) {
  .cultureSectionWrap .imgBox {
    width: 772px;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .cultureSectionWrap .imgBox {
    width: 672px;
  }
}
@media (max-width: 1440px) {
  .cultureSectionWrap .imgBox {
    width: 600px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .cultureSectionWrap .imgBox {
    width: 522px;
  }
}
@media only screen and (max-width: 1100px) {
  .cultureSectionWrap .imgBox {
    width: 100%;
  }
}
@media (max-width: 1440px) {
  .cultureSectionWrap .titleText {
    margin: 0 0 25px;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .cultureSectionWrap .contBox {
    width: calc(100% - 672px);
  }
}
@media (max-width: 1280px) {
  .cultureSectionWrap .contBox {
    margin-top: 0;
  }
}
@media (max-width: 1440px) {
  .cultureSectionWrap .contBox {
    padding-left: 50px;
  }
}
@media (max-width: 1440px) {
  .cultureSectionWrap .contBox {
    padding-left: 50px;
    width: calc(100% - 600px);
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .cultureSectionWrap .contBox {
    width: calc(100% - 522px);
  }
}

@media (max-width: 1366px) and (max-height: 768px) {
  .cultureSectionWrap .contBox {
    width: calc(100% - 522px);
  }
}
@media only screen and (max-width: 1100px) {
  .cultureSectionWrap .contBox {
    padding-left: 0;
    margin-left: 0;
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 1100px) {
  .cultureSectionWrap .contBox {
    width: 100%;
    margin-bottom: 50px;
  }
}
@media (max-width: 575px) {
  .cultureSectionWrap .contBox {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .cultureSectionWrap .contBox p {
    font-size: 16px;
    line-height: 1.7;
  }
}
@media (max-width: 575px) {
  .cultureSectionWrap .contBox p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 1440px) {
  .cultureSectionWrap .contBox ul li strong {
    font-size: 16px !important;
  }
}
.section.ltr .contBox {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}
@keyframes from-right-in {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
