.solutionSec {
  padding-top: 120px !important;
}
.solutionSec .scrollSection {
  /* height: 150vh; */
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  transition: 2s;
}
.solutionHead {
  margin-bottom: 44px;
}
@media (max-width: 1440px) {
  .solutionSec {
    padding-bottom: 50px !important;
  }
}
@media (max-width: 1280px) {
  .solutionSec {
    padding-top: 120px;
    padding-bottom: 20px !important;
  }
}
@media only screen and (max-width: 768px) {
  .solutionSec {
    padding-top: 80px !important;
    padding-bottom: 50px !important;
  }
}

.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.d-flex {
  display: flex !important;
}
.mb-8 {
  margin-bottom: 8px;
}
.sechead {
  position: relative;
  /* transform: translateX(-100%); */
}
.ltr.sechead h3 {
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
@media (max-width: 1600px) {
  .bannerLeft h2,
  .sechead h3,
  .sechead h3 span {
    font-size: 50px;
    line-height: 74px;
  }
}
@media (max-width: 1440px) {
  .sechead h3,
  .sechead h3 span {
    font-size: 40px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 1024px) {
  .sechead h3,
  .sechead h3 span {
    font-size: 36px !important;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .sechead h3,
  .sechead h3 span {
    font-size: 28px !important;
  }
}

@media only screen and (max-width: 768px) {
  .sechead h3,
  .sechead h3 span {
    font-size: 28px !important;
    line-height: 40px;
  }
}
.solutionHead h3 {
  margin-bottom: 8px;
}
.section.ltr .sechead p {
  margin-bottom: 8px;
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
.solutionSec .ltr.npButton {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}
.section.ltr .sollutionWrap {
  opacity: 0;
  animation: up 0.8s linear forwards;
}
.sollutionWrap {
  /* display: flex; */
  flex-wrap: wrap;
}
.sollutionWrap .eachSol {
  width: calc(25% - 30px);
  margin-right: 40px;
  margin-bottom: 40px;
}
.solImg {
  margin-bottom: 15px;
  height: 180px;
  background-color: #f5f5f5;
  /* padding: 10px; */
}
.eachSol img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.sollutionWrap h5 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  margin-bottom: 8px;
}
.sollutionWrap .eachSol:nth-child(4n) {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .solutionHead {
    flex-direction: column;
    align-items: start !important;
  }
}
@media only screen and (max-width: 768px) {
  .solutionHead {
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 40px;
  }
}
@media (max-width: 1200px) {
  .solutionSec .eachSol {
    margin-right: 20px;
    width: calc(33.33% - 20px);
  }
}
@media only screen and (max-width: 768px) {
  .solutionSec .eachSol {
    width: calc(50% - 12px);
  }
}
@media (max-width: 575px) {
  .solutionSec .eachSol {
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 768px) {
  .eachSol h5 {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1600px) {
  .bannerPoly1 {
    text-align: right;
  }
}
@media only screen and (max-width: 991px) {
  .bannerPoly1 {
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .bannerPoly1 {
    justify-content: center;
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .rightBannerImg .bannerRight .bannerPoly1 img {
    width: 100%;
    /* max-width: 70%; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .rightBannerImg .bannerRight .bannerPoly1 img {
    width: 100%;
  }
}
@media (max-width: 1600px) {
  .bannerPoly1 a {
    padding: 30px;
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .bannerPoly1 a {
    width: 80px;
    height: 80px;
    padding: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .bannerPoly1 a {
    padding: 24px;
    width: 72px;
    height: 72px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .rightBannerImg .bannerRight .bannerPoly1 .bnrPlay img {
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .rightBannerImg .bannerRight .bannerPoly1 img {
    width: 100%;
    /* max-width: 70%; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .rightBannerImg .bannerRight .bannerPoly1 img {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .addr {
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .addr {
    margin-bottom: 24px;
    padding: 13px 20px;
  }
}
@media (max-width: 575px) {
  .addr {
    margin-bottom: 24px;
    padding: 1px 0px;
  }
}
@media (max-width: 1200px) {
  .addr {
    margin-top: 30px;
  }
}
.bannerLeft h2 {
  line-height: 84px;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .bannerLeft h2,
  .sechead h3,
  .sechead h3 span {
    font-size: 50px;
    line-height: 74px;
  }
}

@media (max-width: 1366px) and (max-height: 768px) {
  .titleText,
  .titleText .skyText,
  .bannerLeft h2 {
    line-height: 1.2;
  }
}

@media only screen and (max-width: 991px) {
  .bannerLeft h2.titleText {
    font-size: 36px !important;
  }
}

@media (max-width: 575px) {
  .bannerLeft h2,
  .bannerLeft h2.titleText,
  .bannerLeft .skyText {
    font-size: 30px !important;
    line-height: 48px !important;
  }
}

@media (max-width: 575px) {
  .titleText {
    margin: 0 0 20px;
  }
}

@media (max-width: 575px) {
  .titleText,
  .titleText .skyText {
    font-size: inherit;
    line-height: inherit;
  }
}
@media only screen and (max-width: 768px) {
  .addr {
    position: relative;
  }
}
@media (max-width: 1440px) {
  .bannerRight {
    top: 0;
  }
}

@media (max-width: 1200px) {
  .solutionHead {
    flex-direction: column;
    align-items: start !important;
  }
}
@media only screen and (max-width: 768px) {
  .solutionHead {
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 40px;
  }
}
