.section.ltr .eachMediaBoxAcc:nth-child(1) {
  opacity: 0;
  animation: up 0.8s linear forwards;
}

.section.ltr .eachMediaBoxAcc:nth-child(2),
.trWrap.ltr .trBox:nth-child(2) {
  opacity: 0;
  animation: up 1.1s linear forwards;
}

.section.ltr .eachMediaBoxAcc:nth-child(3),
.trWrap.ltr .trBox:nth-child(3) {
  opacity: 0;
  animation: up 1.3s linear forwards;
}

.section.ltr .eachMediaBoxAcc:nth-child(4),
.trWrap.ltr .trBox:nth-child(4) {
  opacity: 0;
  animation: up 1.5s linear forwards;
}
@keyframes up {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.mediaSectionWrap .mediaWrapAcc {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mediaSectionWrap .mediaWrapAcc::-webkit-scrollbar {
  display: none;
}

.mediaSectionWrap .mediaWrapAcc .eachMediaBox {
  border: 1px solid #aaaaaa;
  border-radius: 20px;
  padding: 25px 20px;
}

.eachMediaBoxAcc {
  width: calc(25% - 30px);
  border: 1px solid #aaaaaa;
  border-radius: 20px;
  min-width: 350px;
}

.mediaSectionWrap .mediaWrapAcc .eachMediaBox figure {
  margin-bottom: 25px;
}

.eachMediaBoxAcc figure {
  margin-bottom: 12px;
}

/* .mediaSectionWrap .mediaWrapAcc .eachMediaBox .mediaText {
    height: 310px;
    margin-bottom: 25px;
} */

.mediaTextAcc {
  padding: 20px;
}

.eachMediaBox img {
  max-width: 100%;
}

.eachMediaBoxAcc img {
  width: 100%;
}
.eachMediaBoxAcc img {
  width: 100%;
}
.mediaSectionWrapA .titleText {
  margin-bottom: 30px;
  font-size: 60px;
  margin: 0 0 30px;
  line-height: 1.5;
  font-weight: 400;
  color: #303030;
}

@media (max-width: 1600px) {
  .mediaSectionWrapA .titleText {
    font-size: 50px;
    line-height: 1.2;
  }
}
@media (max-width: 1440px) {
  .mediaSectionWrapA .titleText {
    font-size: 40px;
  }
}

@media (max-width: 1365px) {
  .eachMediaBoxAcc {
    width: calc(50% - 20px);
    margin-bottom: 20px;
    min-width: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .mediaSectionWrap .mediaWrapAcc .eachMediaBox,
  .eachMediaBoxAcc {
    width: 100%;
  }

  .mediaSectionWrap
    .mediaWrapAcc
    .eachMediaBox
    .eachMediaBoxAcc
    .mediaText
    .mediaTextAcc {
    height: auto;
    overflow-y: visible;
  }
}
.mediaTextAcc p {
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  color: #606060;
}
@media (max-width: 1440px) {
  .mediaTextAcc p,
  .mediaTextAcc p strong {
    font-size: 18px;
    line-height: 1.4;
  }
}

@media (max-height: 705px) {
  .mediaTextAcc p,
  .mediaTextAcc p strong {
    font-size: 14px;
  }
}
