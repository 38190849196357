/* Container styles */
.category-tabs {
  display: flex;
  overflow-x: auto;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  gap: 0.5rem;
  scrollbar-width: none;
  transition: all 0.3s ease;
}

.category-tabs::-webkit-scrollbar {
  display: none;
}

/* Button styles */
.category-tab {
  padding: 12px 24px;
  border-radius: 8px;
  white-space: nowrap;
  border: 1px solid #aaa;
  background-color: transparent;
  color: #4b5563;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18px;
}

.category-tab:hover {
  background-color: #f9fafb;
}

.category-tab.active-tab {
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
  color: white;
  border: none;
  font-weight: 700;
}

/* Card container */
.product-card {
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  padding: 1rem;
  border: 1px solid #aaa;
}

.product-card:hover {
}

/* Image container */
.product-card-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.product-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Card content */
.product-card-content {
  padding: 16px;
}

.product-card-content h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 8px;
}

.product-card-content p {
  color: #4b5563; /* Gray text */
  margin-bottom: 16px;
}

/* Enquire button */
.enquire-button {
  display: flex;
  align-items: center;
  color: #2563eb; /* Blue text */
  gap: 14px;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.enquire-button:hover {
  color: #1e40af; /* Darker blue */
}

.arrow-icon {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

/* Grid container */
.product-grid {
  display: grid;
  grid-template-columns: 1fr; /* Single column for small screens */
  gap: 24px; /* Spacing between items */
}

@media (min-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
  }
}

@media (min-width: 1024px) {
  .product-grid {
    grid-template-columns: repeat(3, 1fr); /* Three columns for large screens */
  }
}

/* Add these styles to your existing CSS file */
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.skeleton-content {
  padding: 16px;
}

.skeleton-title {
  height: 24px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  width: 80%;
}

.skeleton-description {
  height: 16px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 60%;
}

.skeleton-button {
  height: 36px;
  background-color: #f0f0f0;
  border-radius: 4px;
  width: 100%;
}

.no-products-found {
  text-align: center;
  padding: 2rem;
  background: #f9fafb;
  border-radius: 8px;
  margin: 2rem 0;
}

.no-products-found h3 {
  color: #374151;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.no-products-found p {
  color: #6b7280;
  margin-bottom: 1rem;
}

.no-products-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
