.offeringSectionWrap .section {
  width: 100%;
  display: flex;
  transition: 2s;
  align-items: center;
  justify-content: center;
  /* padding: 120px 0 150px; */
  /* padding: 150px 0; */
}
.offeringSectionWrap {
  position: relative;
  overflow: hidden;
  padding-top: 120px !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.offeringSectionWrap .scrollSection {
  width: 100%;

  position: relative;
  overflow: hidden;
  transition: 2s;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.offeringWrap {
  position: relative;
}
.offeringSectionWrap .titleText {
  position: absolute;
  left: 15px;
}
.aosAnim.ltr h2 {
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
@keyframes fade-slide-in-ltr {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.titleText {
  line-height: 1.25;
  font-weight: 400;
  font-size: 60px;
  margin: 0 0 30px;
  color: #303030;
}
@media (max-width: 1600px) {
  .titleText {
    font-size: 50px;
    margin: 0 0 35px;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .titleText {
    line-height: 1.2;
  }
}
@media (max-width: 1440px) {
  .titleText {
    font-size: 40px;
  }
}
.offeringWrap .bigImg {
  bottom: -50px;
  width: calc(100% - 200px);
}
.aosAnim.ltr.animeSecIndex .bigImg {
  opacity: 0;
  animation: scale1 1s linear forwards;
  animation-delay: 1300ms;
}
@keyframes scale1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.offeringWrap .bigImg {
  position: relative;
  width: 100%;
  margin: 0 auto;
  bottom: 0px;
  left: 0;
  right: 0;
  transform-origin: bottom center;
  display: block;
}
img {
  overflow-clip-margin: content-box;
  overflow: clip;
  vertical-align: middle;
  border-style: none;
}
.aosAnim.ltr.animeSecIndex .bigImg {
  opacity: 0;
  animation: scale1 1s linear forwards;
  animation-delay: 1300ms;
}
.aosAnim.ltr.animeSecIndex .mainCircleBg {
  opacity: 0;
  animation: scale1 1s linear forwards;
  animation-delay: 300ms;
}
@keyframes scale1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.mainCircleBg {
  width: 300px;
  height: 300px;
  bottom: -100px;
}
@keyframes scale1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.aosAnim .ltr.animeSecIndex .eachStep.step_01 {
  opacity: 0;
  animation: pos1 0.6s linear forwards;
  animation-delay: 2600ms;
}
/* @media (min-width: 2000px) {
@keyframes pos1{
  0% {
      opacity: 0;
      transform: scale(0);
      left: calc(50% - 250px);
      bottom: -100px;
    }
  
    100% {
      opacity: 1;
      transform: scale(1);
      left: 60px;
      bottom: 30%;
    }
}

@keyframes pos2 {
  0% {
      opacity: 0;
      transform: scale(0);
      right: calc(50% - 250px);
      bottom: -100px;
    }
  
    100% {
      opacity: 1;
      transform: scale(1);
      right: 60px;
      bottom: 30%;
    }
}} */

@keyframes pos1 {
  0% {
    opacity: 0;
    transform: scale(0);
    left: calc(50% - 250px);
    bottom: -100px;
  }

  100% {
    opacity: 1;
    transform: scale(1);
    left: 60px;
    bottom: 30%;
  }
}

@keyframes pos2 {
  0% {
    opacity: 0;
    transform: scale(0);
    right: calc(50% - 250px);
    bottom: -100px;
  }

  100% {
    opacity: 1;
    transform: scale(1);
    right: 60px;
    bottom: 30%;
  }
}
@media (max-width: 1280px) {
  @keyframes pos1 {
    0% {
      opacity: 0;
      transform: scale(0);
      left: calc(50% - 250px);
      bottom: -100px;
    }

    100% {
      opacity: 1;
      transform: scale(1);
      left: 20px;
      bottom: 30%;
    }
  }

  @keyframes pos2 {
    0% {
      opacity: 0;
      transform: scale(0);
      right: calc(50% - 250px);
      bottom: -100px;
    }

    100% {
      opacity: 1;
      transform: scale(1);
      right: 20px;
      bottom: 30%;
    }
  }
}

@media only screen and (max-width: 768px) {
  @keyframes pos1 {
    0% {
      opacity: 0;
      transform: scale(0);
      left: calc(50% - 175px);
      bottom: -100px;
    }

    100% {
      opacity: 1;
      transform: scale(1);
      left: 20px;
      bottom: 50%;
    }
  }

  @keyframes pos2 {
    0% {
      opacity: 0;
      transform: scale(0);
      right: calc(50% - 175px);
      bottom: -100px;
    }

    100% {
      opacity: 1;
      transform: scale(1);
      right: 20px;
      bottom: 30%;
    }
  }
}

@media only screen and (max-width: 640px) {
  @keyframes pos1 {
    0% {
      opacity: 0;
      transform: scale(0);
      left: calc(50% - 110px);
      bottom: -100px;
    }

    100% {
      opacity: 1;
      transform: scale(1);
      left: 10px;
      bottom: 52%;
    }
  }

  @keyframes pos2 {
    0% {
      opacity: 0;
      transform: scale(0);
      right: calc(50% - 110px);
      bottom: -100px;
    }

    100% {
      opacity: 1;
      transform: scale(1);
      right: 10px;
      bottom: 30%;
    }
  }
}

.eachStep {
  display: flex;
  align-items: center;
  position: absolute;
}
.eachStep .playBtn {
  width: 60px;
  height: 60px;
}
.eachStep .playBtn {
  background: rgba(21, 121, 190, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -20px;
  z-index: 3;
}
.eachStep a {
  font-size: 20px;
  transition: 0.6s;

  text-decoration: none !important;
}

.eachStep figure {
  position: relative;
  width: 170px;
  height: 170px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0;
  display: block;
}
.eachStep figure img {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.eachStep figure figcaption {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 22px;
  color: #fff;
  width: 100%;
  text-align: center;
  z-index: 3;
}
figcaption {
  display: block;
}
.figContBox strong {
  font-weight: bolder;
  font-size: 24px;
}
.eachStep p {
  color: #303030;
  background: #ffffff;
  border-radius: 16px;
  margin-left: -70px;
  padding: 10px 40px 10px 90px;
  margin-bottom: 0 !important;
  font-size: 24px;
  line-height: 40px;
  margin-top: 0;
  font-weight: 500;
}
.offeringSectionWrap .figContBox .knowMoreBtn {
  background-color: rgba(21, 121, 190, 0.6);
  padding: 12px 40px 12px 90px;
  margin-left: -70px;
  border-radius: 10px;
}

.knowMoreBtn {
  display: inline-block;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  position: relative;
}
.offeringSectionWrap .figContBox .knowMoreBtn:hover {
  background-color: rgba(21, 121, 190, 1);
  color: white;
}
.offeringSectionWrap .figContBox .knowMoreBtn {
  background-color: rgba(21, 121, 190, 0.6);
  padding: 12px 40px 12px 90px;
  margin-left: -70px;
  border-radius: 10px;
  font-size: 20px;
  display: inline-block;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #ffffff;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.knowMoreBtn:after {
  content: "";
  display: inline-block;
  transition: 0.6s;
  background-image: url(../../../assets/images/icons/rgt_arrow_vector.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-left: 15px;
  position: relative;
  right: 0;
  top: 2px;
}

.aosAnim.ltr.animeSecIndex .eachStep.step_02 {
  opacity: 0;
  animation: pos2 0.6s linear forwards;
  animation-delay: 2600ms;
}

.eachStep {
  display: flex;
  align-items: center;
  position: absolute;
}
.aosAnim.ltr.animeSecIndex .mainCircleBg {
  opacity: 0;
  animation: scale1 1s linear forwards;
  animation-delay: 300ms;
}
@keyframes scale1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.mainCircleBg {
  width: 400px;
  height: 400px;
  margin: auto;
  position: absolute;
  border-radius: 50%;
  left: 0;
  right: 0;
  bottom: -80px;
  width: 400px;
  height: 400px;
  margin: auto;
  position: absolute;
  border-radius: 50%;
  left: 0;
  right: 0;
  bottom: -80px;
  background: radial-gradient(
    84.01% 84.01% at 68.01% 26.93%,
    #288cd1 30.87%,
    #0571bc 93.75%
  );
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 1600px) {
  .mainCircleBg {
    width: 300px;
    height: 300px;
    bottom: -100px;
    box-shadow: -5.614286422729492px -5.614286422729492px 28.07143211364746px 0px
      rgba(0, 0, 0, 0.55) inset;
    background-blend-mode: overlay;
    margin: auto;
    position: absolute;
    border-radius: 50%;
    left: 0;
    background: radial-gradient(
      84.01% 84.01% at 68.01% 26.93%,
      #288cd1 30.87%,
      #0571bc 93.75%
    );
    display: flex;
    align-items: flex-start;
    justify-content: center;
    right: 0;
  }
}
.mainCircleBg img {
  display: inline-block;
  margin: 34% 0 0;
  max-width: 60%;
}

/**responsive**/
@media (max-width: 1440px) {
  .offeringSectionWrap {
    padding-top: 90px;
  }
}
@media (max-width: 1280px) {
  .offeringSectionWrap {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 1024px) {
  .offeringSectionWrap {
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .offeringSectionWrap {
    padding-top: 80px;
  }
}
@media (max-width: 1440px) {
  .offeringSectionWrap .scrollSection {
    height: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .scrollSection,
  .mapSec .scrollSection {
    height: auto !important;
  }
}
@media (max-width: 575px) {
  .offeringWrap {
    height: 360px;
  }
}
.offeringSectionWrap .titleText {
  position: absolute;
  left: 15px;
}
@media (max-width: 1280px) {
  .titleText {
    font-size: 40px;
    line-height: 65px;
    left: 0;
  }
}
@media only screen and (max-width: 991px) {
  .offeringSectionWrap .titleText {
    position: static;
  }
}
@media only screen and (max-width: 1024px) {
  .bannerLeft h2,
  .titleText,
  .titleText .skyText {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 420px) {
  .offeringSectionWrap .titleText {
    margin: 0 0 35px;
  }
}
@media (max-width: 1600px) {
  .mainCircleBg {
    width: 300px;
    height: 300px;
    bottom: 10px;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .mainCircleBg {
    bottom: -100px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .mainCircleBg {
    bottom: -60px;
    width: 260px;
    height: 260px;
  }
}
@media (max-width: 1280px) {
  .mainCircleBg {
    height: 280px;
    width: 280px;
    bottom: -60px;
  }
}
@media (max-width: 1200px) {
  .mainCircleBg {
    height: 300px;
    width: 300px;
  }
}
@media only screen and (max-width: 1024px) {
  .mainCircleBg {
    height: 260px;
    width: 260px;
  }
}
@media only screen and (max-width: 768px) {
  .mainCircleBg {
    height: 200px;
    width: 200px;
    bottom: -40px;
  }
}
@media only screen and (max-width: 991px) {
  .mainCircleBg {
    height: 250px;
    width: 250px;
    bottom: -40px;
  }
}
@media only screen and (max-width: 640px) {
  .mainCircleBg {
    height: 180px;
    width: 180px;
    bottom: -50px;
  }
}
@media (max-width: 575px) {
  .mainCircleBg {
    height: 100px;
    width: 100px;
    bottom: -50px;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .eachStep .playBtn {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .eachStep .playBtn {
    top: 0px;
    left: -15px;
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 1280px) {
  .eachStep .playBtn {
    top: -4px;
    left: -13px;
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .eachStep .playBtn {
    top: -4px;
    left: -7px;
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 640px) {
  .eachStep .playBtn {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 420px) {
  .eachStep .playBtn {
    top: 5px;
    left: -5px;
    width: 24px;
    height: 24px;
    padding: 8px;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .eachStep figure {
    width: 170px;
    height: 170px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .eachStep figure {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 1280px) {
  .eachStep figure {
    width: 140px;
    height: 140px;
  }
}

@media only screen and (max-width: 640px) {
  .eachStep figure {
    width: 110px;
    height: 110px;
  }
}
@media (max-width: 575px) {
  .eachStep figure {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 420px) {
  .eachStep figure {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 1600px) and (max-height: 900px) {
  .eachStep figure figcaption {
    font-size: 22px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .eachStep figure figcaption {
    font-size: 20px;
  }
}
@media (max-width: 1280px) {
  .eachStep figure figcaption {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1024px) {
  .eachStep figure figcaption {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1024px) {
  .eachStep figure figcaption {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .eachStep figure figcaption {
    font-size: 12px;
  }
}
@media (max-width: 420px) {
  .eachStep figure figcaption {
    font-size: 8px;
  }
}

@media (max-width: 1440px) {
  .offeringSectionWrap {
    padding-top: 90px !important;
  }
}
@media (max-width: 1280px) {
  .offeringSectionWrap {
    padding-top: 30px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .offeringSectionWrap {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575px) {
  .offeringSectionWrap {
    padding-top: 20px !important;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .offeringSectionWrap .figContBox .knowMoreBtn {
    padding: 10px 40px 10px 70px;
    margin-left: -60px;
  }
}
@media only screen and (max-width: 991px) {
  .offeringSectionWrap .figContBox .knowMoreBtn {
    margin-left: -30px;
    padding: 10px 20px 10px 34px;
  }
}
@media only screen and (max-width: 640px) {
  .offeringSectionWrap .figContBox .knowMoreBtn {
    top: 0;
    width: 160px;
    font-size: 14px;
    line-height: 18px;
    margin-left: -30px;
    border-radius: 5px;
    padding: 10px 20px 10px 30px;
  }
}

@media (max-width: 575px) {
  .offeringSectionWrap .figContBox .knowMoreBtn {
    top: -5px;
    font-size: 10px;
    line-height: 1;
    border-radius: 4px;
    padding: 5px 10px 5px 36px;
  }
}
@media (max-width: 420px) {
  .offeringSectionWrap .figContBox .knowMoreBtn {
    top: -3px;
    width: 106px;
    font-size: 8px;
    line-height: 10px;
    margin-left: -30px;
    border-radius: 5px;
    padding: 5px 5px 5px 24px;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .offeringWrap .bigImg {
    bottom: -50px;
    width: calc(100% - 200px);
  }
}
@media (max-width: 1440px) {
  .offeringWrap .bigImg {
    bottom: 0px;
    width: calc(100% - 250px);
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .offeringWrap .bigImg {
    width: calc(100% - 300px);
  }
}
.aosAnim.ltr.animeSecIndex .bigImg {
  opacity: 0;
  animation: scale1 1s linear forwards;
  animation-delay: 1300ms;
}
@keyframes scale1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media (max-width: 1280px) {
  .offeringWrap .bigImg {
    bottom: 0px;
    width: calc(100% - 120px);
  }
}
@media only screen and (max-width: 991px) {
  .offeringWrap .bigImg {
    width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  .offeringWrap .bigImg {
    bottom: -10px;
  }
}
@media (max-width: 575px) {
  .offeringWrap .bigImg {
    bottom: 0px;
  }
}
@media (max-width: 420px) {
  .offeringWrap .bigImg {
    bottom: -50px;
  }
}
@media only screen and (max-width: 991px) {
  .figContBox {
    width: calc(100% - 115px);
  }
}
@media (max-width: 575px) {
  .figContBox {
    width: 150px;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .eachStep p {
    font-size: 18px;
    line-height: 28px;
    margin-left: -60px;
    padding: 10px 20px 10px 70px;
  }
}

@media only screen and (max-width: 640px) {
  .eachStep p {
    top: 0px;
    width: 250px;
    font-size: 16px;
    position: relative;
    line-height: 24px;
    border-radius: 10px;
    margin-left: -30px;
    padding: 5px 10px 5px 40px;
  }
}
@media (max-width: 575px) {
  .eachStep p {
    top: 6px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
    padding: 5px 5px 5px 40px;
  }
}
@media (max-width: 420px) {
  .eachStep p {
    top: 9px;
    width: 170px;
    font-size: 12px;
    position: relative;
    line-height: 16px;
    border-radius: 5px;
    margin-left: -20px;
    padding: 2px 5px 2px 24px;
  }
}
@media only screen and (max-width: 1024px) {
  .offeringSectionWrap .animeSecIndex {
    display: block;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .eachStep p strong {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 640px) {
  .eachStep p strong {
    font-size: 16px;

    line-height: 24px;
  }
}
@media (max-width: 575px) {
  .eachStep p strong {
    font-size: 12px;
    line-height: 18px;
  }
}
@media (max-width: 575px) {
  .eachStep p strong {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 420px) {
  .eachStep p strong {
    font-size: 12px;

    line-height: 16px;
  }
}
