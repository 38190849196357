.title {
  font-size: 2.5rem;
  font-weight: bold;
}
.titleContainer {
  padding-top: 2rem;
  margin: 0 auto;
}
.investorRow {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

.investorRow img {
  align-self: center;
  height: auto; /* Ensures the aspect ratio is maintained */
  max-width: 200px; /* Prevents images from exceeding the container */
  object-fit: contain;
}
.imgContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 16px;
  padding: 16px;
  background-color: #fff;
}
.flexView {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  justify-content: space-around;
}
.flexContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sectionTitle {
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 1rem;
}

.sectionTitle .skyText {
  font-size: inherit;
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.sectionContent {
  position: relative;
  overflow: hidden;
}

.mediaRowContainer {
  position: relative;
}

.mediaRow {
  display: flex;
  overflow-x: auto;
  gap: 1.5rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1rem 0rem;
}

.mediaRow::-webkit-scrollbar {
  display: none;
}

.gradientLeft,
.gradientRight {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4rem;
  z-index: 1;
  pointer-events: none;
}

.gradientLeft {
  left: 0;
  background: linear-gradient(to right, white, transparent);
}

.articleImage {
  width: max-content;
  max-width: 100%;
  max-height: 30px;
  height: auto;
  object-fit: contain;
  margin-bottom: 0.75rem;
}

.gradientRight {
  right: 0;
  background: linear-gradient(to left, white, transparent);
}

.articleCard {
  flex: 0 0 auto;
  height: 100%;
  width: 300px;
  min-height: 300px;
}
.mediaCard {
  flex: 0 0 auto;
  width: 300px;
}
.mediaImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.awardCard {
  padding: 0 !important;
}

.mediaTitle {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.mediaDescription {
  font-size: 0.875rem;
  color: #4a5568;
}

.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #e2e8f0;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.24);
}

.articleSource {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.articleTitle {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.mediaRedirectBtn {
  display: flex;
  align-items: center;
  gap: 12px;
}

.newsroomSection {
  padding: 60px 0;
}

.mediaRedirectBtn .readMoreText {
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
}

.mediaRedirectBtn > img {
  width: 16px;
  height: 16px;
}

.articleMeta {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.articleMeta span {
  font-size: 14px;
  color: #718096;
}

.awardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.awardImage {
  width: 100%;
  max-width: 200px;
  height: auto;
  object-fit: contain;
  margin-bottom: 1rem;
}

.awardTitle {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.awardDescription {
  font-size: 0.875rem;
  color: #4a5568;
}

.logo {
  width: 100%;
  height: 100%;
  max-width: 150px;
  object-fit: contain;
  padding-right: 1.5rem;
}

.viewBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.viewBtnContainer > img {
  transform: rotate(90deg);
  height: 16px;
}

.mediaColumnView {
  display: none;
}
.viewMoreBtn {
  color: #1579be;
  font-weight: 600;
  font-size: 18px;
  background: transparent;
}

@media (max-width: 1036px) and (min-width: 768px) {
  .articleCard {
    width: 250px;
  }
  .newsroomSection {
    padding: 40px 0;
  }
}

@media (max-width: 768px) {
  .investorRow img {
    max-width: 100%;
  }
  .imgContainer {
    width: 100%;
    height: 80px;
  }
  .desktopView {
    display: none;
  }
  .newsroomSection {
    padding: 40px 0;
  }
  .flexContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
  }
  .flexView {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  .mediaColumnView {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .mediaCardView {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.32), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    gap: 16px;
    min-height: 120px;
    padding: 16px;
  }
  .mediaCardText {
    height: inherit;
    font-size: 14px;
    line-height: normal;
    display: -webkit-box; /* Use flexbox to enable line clamping */
    -webkit-line-clamp: 2; /* Limit the text to 2 lines */
    -webkit-box-orient: vertical; /* Set box orientation to vertical */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
  }
  .investorFooterText {
    color: #000;
    font-size: 32px;
    font-weight: 400;
  }
  .mediaCardImg {
    display: flex;
    justify-content: flex-end;
  }
  .mediaCardImg img {
    width: 100px;
    height: auto;
    object-fit: cover;
  }
  .title {
    font-size: 1.25rem;
  }

  .sectionTitle {
    font-size: 1rem;
  }

  .articleCard {
    width: 220px;
  }

  .card {
    padding: 0.75rem;
  }

  .articleSource {
    font-size: 0.85rem;
  }

  .articleTitle {
    font-size: 0.75rem;
  }

  .articleMeta {
    font-size: 0.75rem;
  }

  .awardGrid {
    grid-template-columns: 1fr;
    padding: 0.5rem;
  }

  .awardImage {
    max-width: 150px;
  }

  .awardTitle {
    font-size: 0.75rem;
  }

  .awardDescription {
    font-size: 0.75rem;
  }
  .mediaCard {
    width: 250px;
  }

  .mediaTitle {
    font-size: 0.75rem;
  }

  .mediaDescription {
    font-size: 0.8rem;
  }
  .investorRow {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
