@media (min-width: 768px) {
  .csrSec1 .eventImgM img {
    border-radius: 20px 0 0 20px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .csrSec1 .eventImgM img {
    border-radius: 20px 20px 0 0;
  }
}

@media screen and (max-width: 990px) {
  .csrSec1 .eventImgM img {
    border-radius: 20px 20px 0 0;
  }
}
.csrSec1 p {
  width: 75%;
}
@media (max-width: 400px) {
  .csrSec1 p {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .tabContBox ul li img {
    width: 14px;
    margin-right: 3px;
  }
}
.csrSec1 a {
  color: white;
  margin: 0 80px 80px;
  width: fit-content;
}
.csrSec1 .tabContBox:not(:last-child) {
  margin-bottom: 0px;
}
.csrSec1 .col-md-6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1290px) {
  .csrSec1 a {
    margin: 0 60px 50px;
  }
  .csrSec1 .eventFull .tabContBox {
    padding: 50px;
  }
}
@media (max-width: 1170px) {
  .csrSec1 .eventFull .tabContBox {
    padding: 40px;
  }
  .csrSec1 a {
    margin: 0 40px 30px;
  }
}
@media (max-width: 575px) {
  .csrSec1 a {
    margin: 0 16px 16px;
  }
}
