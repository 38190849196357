.headerNew {
  padding: 20px 0;
  transition: 0.6s;
  background: linear-gradient(#1579be10, rgba(0, 0, 0, 0));
  backdrop-filter: blur(12px);
  top: 28px;
  left: 0;
  right: 0;
  z-index: 100;
  position: fixed;
}

.grayArrow {
  filter: grayscale(1);
  width: 16px;
  height: 16px;
}

.mobileNavWrapper {
  display: none;
}

.logoWrapper {
  display: flex;
  gap: 16px;
}
.pageTitleNew {
  color: #303030;
  font-size: 16px;
  font-weight: 700;
  padding: 0 16px;
  border-left: 1px solid #aaa;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigationWrapper {
  transition: position 1s ease-in-out;
}

.logoWrapper {
  height: 40px;
}

.logoWrapper img {
  height: 32px;
}

.navigationWrapper ul {
  display: flex;
  align-items: center;
  margin: 0;
}
.navigationWrapper ul li {
  list-style-type: none;
}

.navigationWrapper ul li a {
  font-size: 14px;
  color: #000;
}

nav ul li .hiringBtnText {
  border-radius: 4px;
  color: #606060;
  background: transparent;
  a {
    font-weight: 700;
  }
}
.headerText {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerText span {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.getInTouchBtn {
  display: inline-flex;
  padding: 10px 16px;
  justify-content: center;
  line-height: normal;
  align-items: center;
  border-radius: 8px;
  background: var(
    --Blue-Gradient,
    linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%)
  );
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.navigationWrapper ul .seperator {
  color: #aaa;
}

.menuWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.hamburgerMenu {
  display: none;
  padding: 8px;
  border-radius: 4px;
  font-size: 28px;
  cursor: pointer;
  background: #e8f2f8;
  color: #1579be;
}

@media (max-width: 1268px) {
  .headerNew {
    padding: 10px 0;
  }
}

@media (max-width: 1024px) {
  .navigationWrapper {
    display: none;
  }

  .navContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    backdrop-filter: blur(60px);
    padding: 10px 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
  }

  .navSection {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 2.017px 20.171px 0px rgba(0, 0, 0, 0.08);
    gap: 14px;
    padding: 8px 0;
  }

  .imageContainer {
    position: relative;
  }

  .imageText {
    line-height: normal;
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    top: 40%;
    left: 10px;
    color: #fff;
  }

  .sectionText {
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .sectionText strong {
    font-size: inherit;
    line-height: inherit;
  }

  .mobileNavWrapper {
    padding: 20px;
    flex-direction: column;
    gap: 12px;
    z-index: 99;
    background: linear-gradient(45deg, black, transparent);
    position: absolute;
    top: 75px;
    right: 0;
    height: 100vh;
    width: 100%;
    display: flex;
  }

  .hamburgerMenu {
    display: flex;
    align-items: center;
    padding: 4px;
  }

  .navigationWrapper ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .navigationWrapper ul .seperator {
    display: none;
  }

  .navigationWrapper ul li a {
    color: #303030;
    font-weight: 700;
    width: 100%;
  }

  .navigationWrapper ul li {
    background: #fffc;
    padding: 12px 24px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #aaa;
    border-radius: 4px;
  }

  .navigationWrapper ul li::after {
    content: "→";
    position: absolute;
    right: 16px;
    color: #606060;
    font-size: 32px;
  }
}
