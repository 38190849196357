.mediaSectionWrap .section {
  width: 100%;
  display: flex;
  transition: 2s;
  align-items: center;
  justify-content: center;
}
.mediaSectionWrap {
  /* padding-bottom: 40px; */
  padding-top: 40px;
}
.mediaSectionWrap {
  padding-top: 100px;
}

.scrollSection {
  position: relative;
  overflow: hidden;
  transition: 2s;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  /* height: auto !important; */
}
.aosAnim.ltr h2 {
  opacity: 0;
  animation: fade-slide-in-ltr 0.8s linear forwards;
}
@keyframes fade-slide-in-ltr {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.mediaSectionWrap .titleText {
  margin-bottom: 30px;
  font-size: 60px;
  margin: 0 0 30px;
  line-height: 50px;
  font-weight: 400;
  color: #303030;
}
@media (max-width: 1600px) {
  .mediaSectionWrap .titleText {
    font-size: 50px;
  }
}
@media (max-width: 1440px) {
  .mediaSectionWrap .titleText {
    font-size: 40px;
  }
}
.mediaSectionWrap .mediaWrap {
  display: flex;
  gap: 24px;
  overflow-x: scroll;
  justify-content: space-between;
}
.section.ltr .eachMediaBox:nth-child(1) {
  opacity: 0;
  animation: up 0.8s linear forwards;
}
@keyframes up {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.mediaSectionWrap .mediaWrap .eachMediaBox figure {
  margin-bottom: 25px;
}
figure {
  margin: 0 0 1rem;
  display: block;
}
.eachMediaBox img {
  /* max-height: 60px; */
  max-width: 100%;
}
.mediaSectionWrap .mediaWrap .eachMediaBox .mediaText {
  height: auto;
  overflow-y: visible;
  /* padding-right: 10px; */
  margin-bottom: 25px;
}
.mediaSectionWrap .mediaWrap .eachMediaBox .mediaText {
  height: 340px;
}
@media (max-width: 1600px) {
  .mediaSectionWrap .mediaWrap .eachMediaBox .mediaText {
    height: 300px;
  }
}
@media (max-width: 1440px) {
  .mediaSectionWrap .mediaWrap .eachMediaBox .mediaText {
    height: 190px;
  }
}

.knowMoreBtn.gradientBtn {
  font-size: 28px;
  position: absolute;
  bottom: 16px;
}
.knowMoreBtn.gradientBtn {
  background: linear-gradient(90deg, #1579be 0%, rgba(21, 121, 190, 0.6) 100%);

  -webkit-text-fill-color: transparent;
  background-clip: text;

  font-weight: 700;

  line-height: 35px;
}

.knowMoreBtn::after {
  width: 14px;
  height: 13px;
  top: 4px;
  right: 5px;
  margin-left: 7px;
  content: "";
  display: inline-block;
  transition: 0.6s;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.knowMoreBtn.gradientBtn:after {
  background-image: url(../../../assets/images/icons/rgt_arrow_vector_bl.png);
}
.mediaSectionWrap .mediaWrap .eachMediaBox {
  flex: 0 0 24%;
  position: relative;
  border: 1px solid #aaaaaa;
  border-radius: 20px;
  padding: 25px 20px;
}
.mediaText p {
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  color: #606060;
}
@media (max-width: 1440px) {
  .mediaText p {
    font-size: 18px;
    line-height: 1.4;
  }
}

@media (max-height: 705px) {
  .mediaText p {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .mediaText p {
    font-size: 16px;
    line-height: 26px;
  }
}
/* @media (max-width:1600px) {p
  {font-size: 16px;
}} */
@media (max-width: 1440px) {
  p {
    font-size: 18px;
    line-height: 1.4;
  }
}

@media (max-height: 705px) {
  p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .mediaSectionWrap .mediaWrap .eachMediaBox,
  .eachMediaBoxAcc {
    width: 100%;
  }
}
@media (max-width: 1365px) {
  .mediaSectionWrap .mediaWrap .eachMediaBox .mediaText {
    height: auto;
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 25px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .mediaSectionWrap .mediaWrap .eachMediaBox .mediaText {
    height: 220px;
  }
}
@media (max-width: 1365px) {
  .mediaSectionWrap .mediaWrap .eachMediaBox .mediaText {
    height: auto;
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 25px;
  }
}
@media (max-width: 1365px) {
  .mediaSectionWrap {
    padding-bottom: 80px !important;
  }
}
@media (max-width: 1440px) {
  .cultureSectionWrap,
  .mediaSectionWrap {
    padding-top: 60px !important;
  }
}
@media (max-width: 1280px) {
  .mediaSectionWrap {
    padding-bottom: 40px !important;
  }
}

@media (max-width: 1280px) {
  .cultureSectionWrap,
  .mediaSectionWrap {
    padding-top: 40px !important;
  }
}
@media only screen and (max-width: 768px) {
  .mediaSectionWrap {
    padding-bottom: 20px !important;
  }
  .mediaSectionWrap .mediaWrap {
    flex-direction: column;
  }
}
