.limited-time-offer-section {
  padding: 80px 0;
  background-color: #1a1a2e;
  position: relative;
  overflow: hidden;
}

.limited-time-offer-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.1) 1px,
    transparent 1px
  );
  background-size: 20px 20px;
  opacity: 0.1;
  z-index: 0;
}

.limited-time-offer-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.offer-content {
  text-align: center;
  color: white;
}

.offer-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.offer-description {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.countdown-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-number {
  font-size: 3rem;
  font-weight: 700;
  color: #1579be;
  line-height: 1;
  margin-bottom: 5px;
}

.countdown-label {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(21, 122, 190, 0.6);
  }

  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(255, 107, 107, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 107, 107, 0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .offer-title {
    font-size: 2rem;
  }

  .offer-description {
    font-size: 1.1rem;
  }

  .countdown-container {
    gap: 20px;
  }

  .countdown-number {
    font-size: 2.5rem;
  }
}

@media (max-width: 576px) {
  .limited-time-offer-section {
    padding: 60px 0;
  }

  .offer-title {
    font-size: 1.8rem;
  }

  .offer-description {
    font-size: 1rem;
  }

  .countdown-container {
    gap: 15px;
  }

  .countdown-number {
    font-size: 2rem;
  }

  .countdown-label {
    font-size: 0.8rem;
  }
}
