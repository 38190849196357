.foundry-data-tracking-page {
  font-family: "Inter", sans-serif;
}

.content-wrapper {
  margin: 0 auto;
}

/* Animation classes */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.slide-in-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.slide-in-left.visible {
  opacity: 1;
  transform: translateX(0);
}

.slide-in-right {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.slide-in-right.visible {
  opacity: 1;
  transform: translateX(0);
}

/* Section spacing */
section {
  margin: 80px 0;
  position: relative;
}

/* Button styles */
.cta-button {
  background-color: #4361ee;
  color: white;
  font-weight: 600;
  padding: 14px 28px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
  text-align: center;
}

.cta-button:hover {
  background-color: #3a56d4;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(67, 97, 238, 0.15);
}

.secondary-button {
  background-color: transparent;
  color: #4361ee;
  border: 2px solid #4361ee;
  font-weight: 600;
  padding: 12px 26px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
  text-align: center;
}

.secondary-button:hover {
  background-color: rgba(67, 97, 238, 0.1);
  transform: translateY(-2px);
}

/* Responsive styles */
@media (max-width: 768px) {
  section {
    margin: 60px 0;
  }

  .cta-button,
  .secondary-button {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  section {
    margin: 40px 0;
  }
}
