.event2 .titleText {
    font-size: 64px;
    font-weight: 700;
    line-height: 84px;
    margin-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 0px;
}@media only screen and (max-width: 768px) {.csrSec .eventHead h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}}@media (max-height: 705px) {p {
    font-size: 14px;}
}@media (max-width: 575px) { p {
    font-size: 16px;
    line-height: 26px;
}}event1 csrSec .event2 .titleText {padding: 0px !important}@media (max-width: 1366px) {.event2 .titleText {
    font-size: 50px;
    padding-top: 0px;
}}@media (max-width: 1366px) {.event2 .titleText {
    font-size: 50px;
    padding-top: 70px;
}}