@media only screen and (max-width: 768px) {
  .innerBanner {
    padding: 50px 0 50px;
  }
}

.cultureImgPoly {
  clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
  transform: rotate(-20deg) scale(1.25);
  height: 100%;
}

.cultureImgPoly > img {
  object-fit: contain;
  transform: rotate(20deg);
}

.culture01 {
  top: -50px;
  left: 60px;
}

.culture02 {
  top: -30px;
  right: 24%;
}

.culture03 {
  top: 29%;
  left: 18%;
}

.culture04 {
  top: 38%;
  right: 12%;
}

.culture05 {
  top: 70%;
  left: 25%;
}

.culture06 {
  bottom: -80px;
  right: 3%;
}
.joblink:visited {
  color: white;
}
@media (max-width: 1600px) {
  .culture01 {
    top: -50px;
    left: 0px;
  }

  .culture02 {
    top: -20px;
    right: 21%;
  }

  .culture03 {
    top: 29%;
    left: 16%;
  }

  .culture04 {
    top: 48%;
    right: 10%;
  }

  .culture05 {
    top: 74%;
  }

  .culture06 {
    bottom: -220px;
    right: 0;
  }
}
@media (max-width: 1366px) {
  .culture01 {
    top: -32px;
    left: -40px;
  }

  .culture02 {
    right: 20%;
  }

  .culture04 {
    right: 5%;
  }

  .culture06 {
    bottom: -10px;
    right: -50px;
  }
}

@media (max-width: 1366px) and (max-height: 768px) {
  .culture01 {
    left: -10px;
  }

  .culture04 {
    top: 55%;
    right: 8%;
  }

  .culture05 {
    top: 90%;
    left: 25%;
  }

  .culture06 {
    bottom: -220px;
    right: -20px;
  }
}
@media (max-width: 1280px) {
  .culture01 {
    left: 15px;
  }

  .culture02 {
    top: -20px;
    right: 23%;
  }

  .culture03 {
    left: 17%;
  }

  .culture04 {
    right: 8%;
  }

  .culture06 {
    right: 1%;
    bottom: -90px;
  }
}
@media (max-height: 705px) {
  .clrboxEach {
    width: 180px;
  }

  .clrboxEach h5 {
    line-height: 24px;
  }

  p {
    font-size: 14px;
  }

  .culture01 {
    left: 15px;
  }

  .mrai-certificate > img {
    width: 305px;
    height: auto;
  }

  .culture02 {
    top: 10px;
  }

  .culture03 {
    left: 17%;
  }

  .culture04 {
    top: 50%;
  }

  .culture05 {
    top: 80%;
    left: 28%;
  }

  .culture06 {
    bottom: -130px;
  }
}
@media only screen and (max-width: 1100px) {
  .timelineSection.triangleTopLeftBg {
    padding-bottom: 180px !important;
  }

  .motion-path-demo svg {
    width: 570px;
  }

  .clrboxEach {
    width: 200px;
  }

  .culture01 {
    left: 10px;
  }

  /* .navigationWrapNew{-ms-flex: 0 0 calc(100% - (160px + 25%)); flex: 0 0 calc(100% - (160px + 25%)); max-width: calc(100% - (160px + 25%));} */
  .navigationWrapNew > .row > .navWrapNew {
    -ms-flex: 0 0 calc(100% - 265px);
    flex: 0 0 calc(100% - 265px);
    max-width: calc(100% - 265px);
  }

  .navigationWrapNew > .row > .btnWrapNew {
    -ms-flex: 0 0 265px;
    flex: 0 0 265px;
    max-width: 265px;
  }

  .btnWrapNew a.getInTouch {
    font-size: 14px;
    width: 123px;
  }

  .toggleBtn {
    margin-left: 3px;
  }

  .btnWrapNew {
    padding: 0 15px;
  }

  .btnWrapNew a.getInTouch {
    width: 113px;
  }

  .headerWrapNew ul li:not(:last-child) {
    margin-right: 20px;
  }

  .headerWrapNew ul {
    justify-content: flex-end;
  }

  .cultureSectionWrap .cultureRow {
    flex-direction: column-reverse;
  }

  .cultureSectionWrap .imgBox {
    width: 100%;
  }

  .cultureSectionWrap .contBox {
    width: 100%;
    margin-bottom: 50px;
  }

  .cultureSectionWrap .contBox {
    padding-left: 0;
    margin-left: 0;
    margin-top: 1.5rem;
  }

  .cultureSectionWrap ul {
    margin-bottom: 0;
  }
}
.clrboxEach {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.marketbnr {
  background-color: #e8f2f8;
  padding-top: 120px;
  padding-bottom: 0;
  position: relative;
}
.bannerLeft h2 span.skyText {
  font-size: 64px;
  font-weight: 700;
  color: #1579be;
}
@media (max-width: 1440px) {
  .bannerLeft h2 span.skyText {
    font-size: 40px;
    line-height: 1.5;
  }
}
.bannerLeft h2 {
  line-height: 84px;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 30px;
}
.marketbnr .scrollSection {
  height: calc(100vh - 225px) !important;
}
@media (max-width: 1600px) {
  .marketbnr {
    padding-top: 90px;
  }
}
@media (max-width: 1440px) {
  .marketbnr {
    padding-top: 160px;
  }
}
@media (max-width: 1280px) {
  .marketbnr {
    padding-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .marketbnr .scrollSection {
    height: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .marketbnr .col-md-7,
  .marketbnr .col-md-5 {
    flex: 0 0 100.333333%;
    max-width: 100.333333%;
  }
}
@media (max-width: 768px) {
  .marketbnr {
    padding-top: 50px !important;
  }
}
@media (max-width: 420px) {
  .homebnr.marketbnr {
    padding-top: 110px !important;
  }
}
.bannerLeft h2 {
  line-height: 84px;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 30px;
}

.bannerLeft h2 span {
  font-weight: 700;
  color: #1579be;
}
.bannerLeft h2 {
  line-height: 84px;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .bannerLeft h2,
  .sechead h3,
  .sechead h3 span {
    font-size: 50px;
    line-height: 74px;
  }
}

@media (max-width: 1366px) and (max-height: 768px) {
  .titleText,
  .titleText .skyText,
  .bannerLeft h2 {
    line-height: 1.2;
  }
}

@media only screen and (max-width: 991px) {
  .bannerLeft h2.titleText {
    font-size: 36px !important;
  }
}

@media (max-width: 575px) {
  .bannerLeft h2,
  .bannerLeft h2.titleText,
  .bannerLeft .skyText {
    font-size: 30px !important;
    line-height: 48px !important;
  }
}

@media (max-width: 575px) {
  .titleText {
    margin: 0 0 20px;
  }
}

@media (max-width: 575px) {
  .titleText,
  .titleText .skyText {
    font-size: inherit;
    line-height: inherit;
  }
}
.bannerLeft p {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  /* margin-bottom: 60px; */
  color: #606060;
}

.bannerLeft .newDiv {
  color: #303030;
  margin-top: 12px;
  margin-bottom: 60px;
  font-size: 28px;
  font-weight: 500;
}
.col-md-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.bannerRight {
  top: -13px;
  z-index: 5;
  padding: 10px;
  position: relative;
}
.bannerContent.aosAnim .bannerRight {
  opacity: 0;
  animation: from-right-in 0.8s linear forwards;
}
.bannerPoly {
  position: relative;
}
.bannerPoly a {
  padding: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
}
.bannerPoly img {
  width: 100%;
}
.timelineSection {
  padding-bottom: 200px !important;
}
@media (max-width: 1600px) {
  .timelineSection.triangleTopLeftBg {
    padding-bottom: 400px !important;
  }
}
@media (max-width: 1440px) {
  .timelineSection.triangleTopLeftBg {
    padding-bottom: 260px !important;
  }
}
@media only screen and (max-width: 1100px) {
  .timelineSection.triangleTopLeftBg {
    padding-bottom: 180px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .timelineSection.triangleTopLeftBg {
    padding-bottom: 80px !important;
  }
}

.motion-path-demo {
  position: relative;
  top: 0;
  padding-bottom: 70px;
}

.motion-path-demo svg {
  /* width: 100%; */
  width: 670px;
  margin: 0 auto;
  left: 0;
  right: 0;
  /* justify-content: center; */
  position: absolute;
  top: 0;
}

.clrImg {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 16px;
}
.clrboxEach h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: #1579be;
  margin-bottom: 8px;
}

.tabTimelineBox,
.mobTimelineBox {
  display: none;
}

.tabTimelineBox img,
.mobTimelineBox img {
  display: block;
  width: 100%;
}
.clrBox {
  height: 100vh;
  position: relative;
}

.clrboxEach {
  width: 320px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
@media (max-width: 1440px) {
  .clrboxEach {
    width: 270px;
  }
}
@media (max-width: 1280px) {
  .clrboxEach {
    width: 240px;
  }
}
/*.culture01 {
                        top: -50px;
                        left: 60px;
                    }.culture02 {
                        top: -30px;
                        right: 24%;
                    } */
.clrboxEach {
  transition: 0.4s;
  transform: translateY(50px);
}
@media (max-width: 575px) {
  .bannerLeft h2,
  .bannerLeft h2.titleText,
  .bannerLeft .skyText {
    font-size: 30px !important;
    line-height: 48px;
  }
}

@media (max-width: 575px) {
  .bannerLeft h2 {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .cultur {
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .cultur {
    flex-wrap: wrap;
  }
  .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.tabTimelineBox,
.mobTimelineBox {
  display: none;
}
@media (max-width: 575px) {
  .mobTimelineBox {
    display: block;
  }
}

@media only screen and (max-width: 1024px) {
  .tabTimelineBox {
    display: block;
  }
}
@media (max-width: 575px) {
  .tabTimelineBox {
    display: none;
  }
}
.clrboxEach:nth-child(even) {
  align-items: flex-start;
}
.clrboxEach:nth-child(odd) p {
  text-align: right;
  margin-top: 16px;
}
@media (max-height: 705px) {
  .clrboxEach {
    width: 180px;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}
